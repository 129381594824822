import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Amplify } from 'aws-amplify';

import useUserPool from '@users-cognito/requests/hooks/queries/useUserPool';
import getAmplifyConfig from '@users-cognito/helpers/amplify/getAmplifyConfig';

const CognitoContext = createContext({
  isAmplifyConfigured: false,
});

export function useCognitoContext() {
  return useContext(CognitoContext);
}

export function Provider({ children }) {
  const { data: userPool } = useUserPool();
  const [context, setContext] = useState({
    isAmplifyConfigured: false,
    date: new Date(),
  });

  useEffect(() => {
    if (userPool) {
      const config = getAmplifyConfig(userPool);

      Amplify.configure(config);

      setContext((ctx) => ({
        ...ctx,
        isAmplifyConfigured: true,
      }));
    }
  }, [userPool]);

  return (
    <CognitoContext.Provider value={context}>
      {children}
    </CognitoContext.Provider>
  );
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};
