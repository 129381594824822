/**
 * @typedef UserPool
 * @property {string} userPool
 * @property {string} domain
 * @property {string} clientID
 * @property {[string]} identityProviders
 */

/**
 * @returns {Promise<UserPool | null>}
 */
async function getUserPool() {
  const response = await leemons.api('v1/users-cognito/userPool');

  return response.userPool;
}

export default getUserPool;
