import React from 'react';
import { useLocation } from 'react-router-dom';

import { NotificationProvider } from '@bubbles-ui/notifications';
import { useStore } from '@common';
import SocketIoService from '@mqtt-socket-io/service';
import { getCentersWithToken, getSessionConfig, useSession } from '@users/session';
import _ from 'lodash';
import PropTypes from 'prop-types';

import RoomService from '@comunica/RoomService';
import { ChatHub } from '@comunica/components/ChatHub/ChatHub';
import { EVENT_CONFIG_GENERAL, EVENT_CONFIG_PROGRAM } from '@comunica/constants';
import { ComunicaProvider } from '@comunica/context';

const notificationProps = {
  autoClose: 8000,
  transitionDuration: 250,
  containerWidth: 440,
  notificationMaxHeight: 200,
  limit: 5,
  zIndex: 1,
  xOffset: 100,
  type: 'chat',
};

function Provider({ children }) {
  const [store, render] = useStore({ showButton: false, currentRoom: null });
  const session = useSession();
  const centers = getCentersWithToken();
  const location = useLocation();
  const sessionConfig = getSessionConfig();
  const currentProgram = sessionConfig?.program;

  // ····················································
  // METHODS

  const values = React.useMemo(
    () => ({
      openRoom: (room) => {
        store.openRoom = `${room}~~${Date.now()}`;
        render();
      },
      openUserRoom: (user) => {
        store.openRoom = `USERAGENT:${user}~~${Date.now()}`;
        render();
      },
      isChatEnabled: store.enabled,
    }),
    [store, render]
  );

  // ····················································
  // INITIAL DATA LOADING

  async function load() {
    try {
      if (!store.config) {
        const config = await RoomService.getGeneralConfig();
        let isEnabledOnProgram = true; // TRUE by default

        if (currentProgram) {
          const programConfig = await RoomService.getProgramConfig(currentProgram);
          isEnabledOnProgram = _.isNil(programConfig.enableComunica)
            ? true
            : programConfig.enableComunica;
        }

        store.enabled = config.enabled && isEnabledOnProgram;
        render();
      }
    } catch (e) {
      console.error(e);
    }
  }

  React.useEffect(() => {
    const old = store.showButton;
    store.showButton = false;
    if (session) {
      load();
    } else {
      store.config = null;
    }

    if (
      session &&
      centers &&
      centers.length &&
      location.pathname !== '/protected/users/select-profile' &&
      !location.pathname.startsWith('/protected/') &&
      store.enabled
    ) {
      store.showButton = true;
    }
    if (old !== store.showButton) {
      render();
    }
    try {
      RoomService.markAsConnected();
    } catch (e) {
      // Nothing
    }
    const interval = setInterval(() => {
      try {
        RoomService.markAsConnected();
      } catch (e) {
        // Nothing
      }
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [session, location, store.enabled]);

  // ····················································
  // HANDLERS

  function onShowDrawerChange(showDrawer) {
    store.showDrawer = showDrawer;
    render();
  }

  // ····················································
  // SOCKET EVENTS

  SocketIoService.useOn(EVENT_CONFIG_GENERAL, (event, eventData) => {
    store.enabled = eventData.enabled;
    render();
  });

  SocketIoService.useOn(EVENT_CONFIG_PROGRAM, (event, eventData) => {
    const { program, config } = eventData;

    if (program === currentProgram) {
      store.enabled = _.isNil(config.enableComunica) ? true : config.enableComunica;
      render();
    }
  });

  return (
    <ComunicaProvider value={values}>
      <NotificationProvider {...notificationProps} xOffset={store.showDrawer ? 420 : 100}>
        <ChatHub
          showButton={store.showButton}
          openRoom={store.openRoom}
          onShowDrawerChange={onShowDrawerChange}
        />
      </NotificationProvider>
      {children}
    </ComunicaProvider>
  );
}

Provider.propTypes = {
  children: PropTypes.node,
};

export { Provider };
