import { createStyles } from '@bubbles-ui/components';

const ChatListTabsStyles = createStyles((theme) => ({
  listItems: {
    backgroundColor: '#F8F9FB',
  },
  tab: {
    backgroundColor: theme.colors.mainWhite,
    color: theme.other.global.content.color.text.default,
  },
}));

export { ChatListTabsStyles };
