import React from 'react';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

function Provider({ children }) {
  const history = useHistory();

  React.useEffect(() => {
    const handleRouteChange = (location) => {
      // Do nothing for now
    };

    // Listen to changes in the history
    const unlisten = history.listen(handleRouteChange);

    return () => {
      unlisten();
    };
  }, [history]);

  return children;
}

Provider.propTypes = {
  children: PropTypes.node,
};

export { Provider };
