export const OAUTH_PROVIDERS = ['Google', 'Facebook', 'Amazon', 'Apple'];

/**
 *
 * @param {import("@users-cognito/requests/getUserPool").UserPool} userPoolConfig
 */
export default function getAmplifyConfig(userPoolConfig) {
  const {
    userPool: userPoolId,
    clientID: userPoolClientId,
    domain,
    identityProviders: { COGNITO, ...identityProviders },
  } = userPoolConfig;

  const redirectSignIn = `${window.location.origin}/users-cognito/login`;
  const redirectSignOut = `${window.location.origin}/protected/users-cognito/logout`;

  const providers = Object.keys(identityProviders).map((provider) =>
    OAUTH_PROVIDERS.includes(provider) ? provider : { custom: provider }
  );

  return {
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
        signUpVerificationMethod: 'code',
        loginWith: {
          oauth: {
            domain: domain.replace(/^https?:\/\//, ''),
            scopes: [
              'email',
              'openid',
              'profile',
              'aws.cognito.signin.user.admin',
            ],
            redirectSignIn: [redirectSignIn],
            redirectSignOut: [redirectSignOut],
            responseType: 'code',
            providers,
          },

          email: true,
        },
        mfa: {
          totpEnabled: true,
          status: 'optional',
        },
      },
    },
  };
}
