import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Menu,
  Stack,
  Button,
  Popover,
  TextInput,
  ActionButton,
  CheckBoxGroup,
} from '@bubbles-ui/components';
import { FilterIcon, RemoveIcon, SearchIcon } from '@bubbles-ui/icons/outline';
import { SettingMenuVerticalIcon } from '@bubbles-ui/icons/solid';
import { ProgramBarSelector } from '@academic-portfolio/components/ProgramBarSelector';
import { ChatListHeaderStyles } from './ChatListHeader.styles';

function ChatListHeader({
  t,
  programs,
  programsConfig,
  isStudent,
  isTeacher,
  canAddGroup,
  roomTypes,
  filter,
  typeFilters,
  onSelectProgram,
  onClose,
  onFocusSwitch,
  onNewChat,
  onNewGroup,
  onChangeFilter,
  onChangeTypeFilter,
  onCleanTypesFilter,
}) {
  const { classes } = ChatListHeaderStyles(
    { filter },
    { name: 'ChatListHeader' }
  );

  const menuItems = React.useMemo(() => {
    const m = [
      {
        children: t('focusMode'),
        onClick: onFocusSwitch,
      },
      {
        children: t('newChat'),
        onClick: onNewChat,
      },
    ];
    if (canAddGroup) {
      m.push({
        children: t('newGroup'),
        onClick: onNewGroup,
      });
    }
    return m;
  }, [canAddGroup, t]);

  return (
    <>
      <Stack
        className={classes.header}
        fullWidth
        justifyContent="space-between"
        alignItems="center"
      >
        {/* <ActionButton onClick={onKim} icon={<PluginKimIcon width={16} height={16} />} />
         */}
        <Stack alignItems="center" justifyContent="center">
          {(isStudent || isTeacher) && (
            <Box sx={{ display: 'grid' }}>
              {!programs || programs?.length > 1 ? (
                <ProgramBarSelector
                  onChange={onSelectProgram}
                  hideIcon={true}
                  chevronSize={16}
                  itemSelectedFontSize="14px"
                  itemSelectedFontWeight={400}
                  clear={true}
                  filterFn={(p) => programsConfig[p.id]?.enableComunica}
                />
              ) : (
                <Box
                  sx={{
                    minWidth: 0,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {programs?.[0]?.label}
                </Box>
              )}
            </Box>
          )}
        </Stack>
        <Stack justifyContent="end" alignItems="center" spacing={2}>
          <Menu
            control={
              <ActionButton
                icon={<SettingMenuVerticalIcon width={16} height={16} />}
              />
            }
            items={menuItems}
            dropdownWidth={150}
          ></Menu>

          <ActionButton
            onClick={onClose}
            icon={<RemoveIcon width={16} height={16} />}
          />
        </Stack>
      </Stack>
      <Box className={classes.input}>
        <TextInput
          value={filter}
          onChange={onChangeFilter}
          placeholder={t('search')}
          sx={{ width: '100%' }}
          icon={<SearchIcon width={16} height={16} />}
          rightSection={
            !!filter && (
              <ActionButton
                icon={<RemoveIcon width={16} height={16} />}
                onClick={() => {
                  onChangeFilter('');
                }}
              />
            )
          }
        />
        {!!roomTypes && !!filter && (
          <Popover
            target={
              <ActionButton icon={<FilterIcon width={16} height={16} />} />
            }
          >
            <Stack direction="column" spacing={1} sx={{ padding: 6 }}>
              <CheckBoxGroup
                orientation="vertical"
                direction="column"
                onChange={onChangeTypeFilter}
                data={roomTypes.map((type) => ({
                  label: t(type?.replace(/\./g, '_')),
                  value: type,
                  checked: typeFilters?.includes(type),
                }))}
              />
              <Box sx={{ padding: 8 }}>
                <Button
                  onClick={onCleanTypesFilter}
                  fullWidth
                  variant="outline"
                >
                  {t('clean')}
                </Button>
              </Box>
            </Stack>
          </Popover>
        )}
      </Box>
    </>
  );
}

ChatListHeader.propTypes = {
  t: PropTypes.func,
  programs: PropTypes.array,
  programsConfig: PropTypes.object,
  isStudent: PropTypes.bool,
  isTeacher: PropTypes.bool,
  canAddGroup: PropTypes.bool,
  roomTypes: PropTypes.array,
  filter: PropTypes.string,
  typeFilters: PropTypes.array,
  onSelectProgram: PropTypes.func,
  onClose: PropTypes.func,
  onFocusSwitch: PropTypes.func,
  onNewChat: PropTypes.func,
  onNewGroup: PropTypes.func,
  onChangeFilter: PropTypes.func,
  onChangeTypeFilter: PropTypes.func,
  onCleanTypesFilter: PropTypes.func,
};

export { ChatListHeader };
