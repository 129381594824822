import React from 'react';
import { Box, Highlight } from '@bubbles-ui/components';
import PropTypes from 'prop-types';
import { useTransformDate } from '@comunica/hooks/useTransformDate';
import { ChatMessageSearchStyles } from 'leemons-plugin-comunica-frontend-react-private/src/components/ChatMessageSearch/ChatMessageSearch.styles';
import getRoomParsed from '@comunica/helpers/getRoomParsed';

function ChatMessageSearch({ message, onClick = () => {}, highlight, t }) {
  const { classes } = ChatMessageSearchStyles(
    {},
    { name: 'ChatMessageSearch' }
  );

  const room = React.useMemo(() => getRoomParsed(message.room), [message.room]);
  const date = useTransformDate(message.createdAt);

  return (
    <Box onClick={onClick} className={classes.item}>
      <Box className={classes.head}>
        <Box className={classes.name}>
          {t(room.name, room.nameReplaces, false, room.name)}
        </Box>
        <Box className={classes.date}>{date}</Box>
      </Box>
      <Box className={classes.content}>
        <Highlight highlight={highlight}>{message.message.content}</Highlight>
      </Box>
    </Box>
  );
}

ChatMessageSearch.propTypes = {
  t: PropTypes.func,
  message: PropTypes.any,
  originalRooms: PropTypes.any,
  highlight: PropTypes.string,
  onClick: PropTypes.func,
};

export { ChatMessageSearch };
export default ChatMessageSearch;
