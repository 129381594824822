import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Avatar, Box, ImageLoader } from '@bubbles-ui/components';
import { getAssetUrl } from '@leebrary/helpers/prepareAsset';
import selectFile from '@leebrary/helpers/selectFile';
import getChatUserAgent from '@comunica/helpers/getChatUserAgent';
import {
  CHAT_ROOM_1ON1_PARENT_TYPE,
  CHAT_ROOM_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_1ON1_TYPE,
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  CHAT_ROOM_SUBJECT_TEACHERS_TYPE,
} from '@comunica/constants';
import isTeacherByRoom from '@comunica/helpers/isTeacherByRoom';
import { ClassIcon, GraduationHatIcon } from '@bubbles-ui/icons/solid';
import { RoomAvatarStyles } from './RoomAvatar.styles';

function RoomAvatar({
  room,
  isHeader,
  onImageChange,
  size = 48,
  style: styleProp,
  nameReplaces,
}) {
  let style = styleProp;
  const { classes } = RoomAvatarStyles(
    {
      room,
      size,
    },
    { name: 'RoomAvatar' }
  );

  async function click() {
    if (_.isFunction(onImageChange) && room.type === 'group') {
      const file = await selectFile();
      onImageChange(file[0]);
    }
  }

  const isTeacher = isTeacherByRoom(room);
  const isRoomStudentTeacherSubject =
    room.type === CHAT_ROOM_SUBJECT_TEACHERS_TYPE;

  const is1on1Room =
    room.type === CHAT_ROOM_1ON1_TYPE ||
    room.type === CHAT_ROOM_1ON1_PARENT_TYPE;

  const isRoomStudentTeacherActivity =
    room.type === CHAT_ROOM_ACTIVITY_1ON1_TYPE;

  const isRoomClass = room.type === CHAT_ROOM_SUBJECT_ALL_TYPE;

  const avatar = React.useMemo(() => {
    const result = {};
    let { image, icon, bgColor, imageIsUser, imageIsUrl } = room;
    let customSize;

    if (room.metadata?.headerImage) {
      image = room.metadata.headerImage;
    }
    if (room.metadata?.headerIcon) {
      icon = room.metadata.headerIcon;
    }
    if (room.metadata?.headerBgColor) {
      bgColor = room.metadata.headerBgColor;
    }

    if (
      Object.prototype.hasOwnProperty.call(room.metadata, 'headerImageIsUser')
    ) {
      imageIsUser = room.metadata.headerImageIsUser;
    }

    if (
      Object.prototype.hasOwnProperty.call(room.metadata, 'headerImageIsUrl')
    ) {
      imageIsUrl = room.metadata.headerImageIsUrl;
    }

    if (
      isRoomStudentTeacherSubject ||
      isRoomStudentTeacherActivity ||
      is1on1Room
    ) {
      imageIsUser = true;
      style = 'circle';
      customSize = 48;
    }

    result.color = bgColor || 'transparent';
    result.bgColor = style === 'outline' ? 'transparent' : bgColor;

    const setImage = () => {
      if (imageIsUser) {
        result.image = (
          <Avatar
            image={image}
            fullName={room.name}
            size={size === 48 ? 'xmd' : 'md'}
            customSize={customSize}
          />
        );
      } else {
        const imageUrl = `${imageIsUrl ? image : getAssetUrl(image)}${
          room.imageSeed ? `&seed=${room.imageSeed}` : ''
        }`;
        result.image = (
          <ImageLoader
            className={classes.image}
            src={imageUrl}
            forceImage
            withPlaceholder
            placeholder={
              <Avatar
                color={result.color}
                fullName={room.name}
                size={size === 48 ? 'xmd' : 'md'}
              />
            }
            width={size}
            height={size}
          />
        );
      }
    };

    const setIcon = () => {
      const iconColor = style === 'outline' ? result.color : '#ffffff';
      result.icon = (
        <ImageLoader
          src={room.metadata.iconIsUrl ? room.icon : getAssetUrl(room.icon)}
          withPlaceholder
          fillCurrent={iconColor}
          placeholder={
            <Avatar
              color={result.color}
              fullName={room.name}
              size={size === 48 ? 'xmd' : 'md'}
            />
          }
          width={result.image ? size * 0.2142 : size * 0.4642}
          height={result.image ? size * 0.2142 : size * 0.4642}
          forceImage
        />
      );
    };

    if (room.type === CHAT_ROOM_SUBJECT_ALL_TYPE) {
      if (icon) {
        setIcon();
      } else if (image) {
        setImage();
      }
    } else if (image) {
      setImage();
    } else if (icon) {
      setIcon();
    }

    if (room.type === CHAT_ROOM_SUBJECT_ALL_TYPE) {
      if (!result.icon) {
        setImage();
      }
    } else if (!result.image) {
      setIcon();
    }

    if (!image && !icon) {
      result.image = (
        <Avatar
          color={result.color}
          fullName={nameReplaces}
          size={size === 48 ? 'xmd' : 'md'}
        />
      );
    }

    const userAgentData = getChatUserAgent(room.userAgents);

    if (
      (isRoomStudentTeacherSubject ||
        room.type === CHAT_ROOM_1ON1_TYPE ||
        room.type === CHAT_ROOM_1ON1_PARENT_TYPE) &&
      room.userAgents.length === 2 &&
      userAgentData.connected
    ) {
      result.connected = (
        <Box
          sx={(theme) => ({
            width: 16,
            height: 16,
            borderRadius: '50%',
            backgroundColor:
              theme.other.global.background.color.positive.default,
          })}
        ></Box>
      );
    }

    if (isRoomClass) {
      result.room1on1Deep = true;
      /*
      result.image = (
        <Avatar
          icon={<ClassIcon color={result.color} />}
          fullName={room.name}
          customSize={customSize}
          color={'transparent'}
        />
      );
      */
    }

    if (
      !isTeacher &&
      (isRoomStudentTeacherSubject || isRoomStudentTeacherActivity)
    ) {
      result.room1on1Deep = true;
      result.image = (
        <Avatar
          icon={<GraduationHatIcon color={result.color} />}
          fullName={room.name}
          customSize={customSize}
          color={'transparent'}
        />
      );
    }
    return result;
  }, [
    JSON.stringify(room.userAgents),
    room.icon,
    room.attached,
    room.image,
    room.metadata,
    room.bgColor,
    room.imageSeed,
  ]);

  return (
    <Box className={classes.itemImage}>
      <Box className={classes.itemContent} onClick={click}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!!avatar.image && (
          <>
            <Box
              style={
                avatar.room1on1Deep
                  ? {
                      backgroundColor: 'transparent',
                      border: `2px solid ${avatar.color}`,
                    }
                  : {
                      backgroundColor: avatar.color,
                      border: `2px solid ${avatar.color}`,
                    }
              }
              className={classes.itemImageContainer}
            >
              {avatar.image}
            </Box>
            {!!avatar.attached && (
              <Box className={classes.attachedIcon}>{avatar.attached}</Box>
            )}
            {!!avatar.connected && (
              <Box className={classes.connected}>{avatar.connected}</Box>
            )}
          </>
        )}
        {!avatar.image && !!avatar.icon && (
          <>
            <Box
              style={
                !isRoomStudentTeacherSubject &&
                !isRoomStudentTeacherActivity && {
                  backgroundColor: avatar.bgColor,
                  border: `2px solid ${avatar.color}`,
                }
              }
              className={classes.itemIconContainer}
            >
              {avatar.icon}
            </Box>
            {!!avatar.attached && (
              <Box className={classes.attachedIcon}>{avatar.attached}</Box>
            )}
            {!!avatar.connected && (
              <Box className={classes.connected}>{avatar.connected}</Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

RoomAvatar.propTypes = {
  room: PropTypes.any,
  size: PropTypes.number,
  isHeader: PropTypes.boolean,
  onImageChange: PropTypes.func,
  nameReplaces: PropTypes.any,
  style: PropTypes.string,
};

export { RoomAvatar };
export default RoomAvatar;
