import useTranslateLoader from '@multilanguage/useTranslateLoader';
import prefixPN from '@comunica/helpers/prefixPN';

function isSameDate(date1, date2) {
  return date1.toDateString() === date2.toDateString();
}

function isYesterday(today, date) {
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return isSameDate(yesterday, date);
}

function isThisWeek(today, date) {
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());
  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
  return date >= startOfWeek && date <= endOfWeek;
}

function getDayOfWeek(date) {
  const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  return daysOfWeek[date.getDay()];
}

function getHourMinutes(date) {
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

function getDayMonthYear(date) {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function useTransformDate(date) {
  const [t] = useTranslateLoader(prefixPN('dates'));
  const today = new Date();
  const dateObj = new Date(date);

  if (isSameDate(today, dateObj)) {
    return getHourMinutes(dateObj);
  }
  if (isYesterday(today, dateObj)) {
    return t('yesterday');
  }
  if (isThisWeek(today, dateObj)) {
    return t(getDayOfWeek(dateObj));
  }
  return getDayMonthYear(dateObj);
}
