import { createStyles } from '@bubbles-ui/components'; // eslint-disable-next-line import/prefer-default-export

const ChatDrawerHeaderStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing[3],
    paddingRight: theme.spacing[5],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.mainWhite,
    zIndex: 2,
    height: 80,
    button: {
      color: theme.other.buttonAction.content.color.primary.default,
    },
  },
  headerWrapper: {
    backgroundColor: theme.colors.mainWhite,
  },
  input: {
    paddingInline: theme.spacing[5],
    paddingTop: theme.spacing[1],
  },
  searchResultsWrapper: {
    paddingInline: theme.spacing[5],
    display: 'flex',
    gap: theme.spacing[4],
    alignItems: 'center',
    direction: 'row',
  },
  searchResultsCount: {
    fontSize: 14,
    selfAligh: 'left',
    width: '100%',
  },
  hideSearchButton: {
    textAlign: 'left',
  },
}));

export { ChatDrawerHeaderStyles };
