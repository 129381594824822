import React from 'react';
import PropTypes from 'prop-types';
import _, { noop } from 'lodash';
import {
  Box,
  Menu,
  Stack,
  Button,
  TextInput,
  ActionButton,
} from '@bubbles-ui/components';
import {
  ChevDownIcon,
  ChevronLeftIcon,
  ChevUpIcon,
  RemoveIcon,
  SearchIcon,
} from '@bubbles-ui/icons/outline';
import RoomHeader from '@comunica/components/RoomHeader/RoomHeader';
import { SettingMenuVerticalIcon } from '@bubbles-ui/icons/solid';
import { ChatDrawerHeaderStyles } from './ChatDrawerHeader.styles';

function ChatDrawerHeader({
  t,
  td,
  room,
  subjectRooms,
  searchMode,
  selectedMessage,
  menuItems,
  toggleInfo,
  searchCount,
  searchCountIndex,
  onReturn,
  onClose = noop,
  onSearchChange = noop,
  onSearchGoUp = noop,
  onSearchGoDown = noop,
  onCancelSearch = noop,
}) {
  const { classes } = ChatDrawerHeaderStyles();

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.header}>
        {_.isFunction(onReturn) ? (
          <Button
            variant="link"
            color="secondary"
            onClick={() => {
              if (_.isFunction(onReturn)) onReturn();
            }}
            leftIcon={<ChevronLeftIcon />}
            iconSize={8}
          >
            {td('returnToChats')}
          </Button>
        ) : (
          <Box></Box>
        )}

        <Box className={classes.headerRight}>
          <Menu
            control={
              <ActionButton
                icon={<SettingMenuVerticalIcon width={16} height={16} />}
              />
            }
            items={menuItems}
            dropdownWidth={150}
          />

          <ActionButton
            onClick={onClose}
            icon={<RemoveIcon width={16} height={16} />}
          />
        </Box>
      </Box>
      {!!searchMode && (
        <Box className={classes.headerWrapper}>
          <Box className={classes.input}>
            <TextInput
              style={{ width: '100%' }}
              required
              value={selectedMessage?.query}
              onChange={onSearchChange}
              icon={<SearchIcon width={16} height={16} />}
            />
          </Box>
          <Stack
            justifyContent="space-between"
            fullWidth
            sx={(theme) => ({
              paddingInline: theme.spacing[5],
              paddingBlock: theme.spacing[1],
            })}
          >
            <Stack alignItems="center" spacing={4}>
              <Stack>
                <ActionButton onClick={onSearchGoUp}>
                  <ChevUpIcon width={18} height={18} />
                </ActionButton>
                <ActionButton onClick={onSearchGoDown}>
                  <ChevDownIcon width={18} height={18} />
                </ActionButton>
              </Stack>

              {searchCount > 0 && (
                <Box>
                  {t('searchResultsCount', {
                    index: searchCountIndex,
                    total: searchCount,
                  })}
                </Box>
              )}
            </Stack>
            <Button
              variant="linkInline"
              onClick={onCancelSearch}
              className={classes.hideSearchButton}
            >
              <Box p={5}>{t('hideSearch')}</Box>
            </Button>
          </Stack>
        </Box>
      )}
      {!searchMode && !!room && (
        <RoomHeader
          t={td}
          room={room}
          onShowInfo={toggleInfo}
          subjectRooms={subjectRooms}
        />
      )}
    </Box>
  );
}

ChatDrawerHeader.propTypes = {
  t: PropTypes.func,
  td: PropTypes.func,
  room: PropTypes.object,
  subjectRooms: PropTypes.array,
  searchMode: PropTypes.bool,
  selectedMessage: PropTypes.object,
  menuItems: PropTypes.array,
  toggleInfo: PropTypes.func,
  searchCount: PropTypes.number,
  searchCountIndex: PropTypes.number,
  onClose: PropTypes.func,
  onReturn: PropTypes.func,
  onSearchChange: PropTypes.func,
  onSearchGoUp: PropTypes.func,
  onSearchGoDown: PropTypes.func,
  onCancelSearch: PropTypes.func,
};

export { ChatDrawerHeader };
