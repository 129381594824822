import React from 'react';
import PropTypes from 'prop-types';
import {
  TotalLayoutContainer,
  TotalLayoutStepContainer,
  Stack,
  Box,
} from '@bubbles-ui/components';
import ChatListDrawerItem from '@comunica/components/ChatListDrawerItem/ChatListDrawerItem';

const TAB_PANEL_HEIGHT = 'calc(100vh - 173px)';

function ChatListTab({
  t,
  classes,
  hideFav,
  scrollRef,
  rooms,
  children,
  onRoomClick,
}) {
  return (
    <TotalLayoutContainer
      scrollRef={scrollRef}
      noHeaderShadow
      styleRoot={{ height: TAB_PANEL_HEIGHT }}
    >
      <Stack ref={scrollRef} style={{ overflow: 'auto' }} fullWidth>
        <TotalLayoutStepContainer noMargin fullWidth clean>
          <Box className={classes.listItems}>
            {rooms?.map((room) => (
              <ChatListDrawerItem
                t={t}
                key={room.id}
                room={room}
                hideFav={hideFav}
                onClick={() => onRoomClick(room)}
              />
            ))}
            {!rooms?.length && (
              <Stack
                direction="column"
                alignItems="center"
                fullWidth
                fullHeight
                spacing={4}
                sx={{ textAlign: 'center', paddingInline: 50, paddingTop: 50 }}
              >
                {children}
              </Stack>
            )}
          </Box>
        </TotalLayoutStepContainer>
      </Stack>
    </TotalLayoutContainer>
  );
}

ChatListTab.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object,
  scrollRef: PropTypes.object,
  rooms: PropTypes.array,
  onRoomClick: PropTypes.func,
  hideFav: PropTypes.bool,
  children: PropTypes.node,
};

export { ChatListTab };
