import React from 'react';
import PropTypes from 'prop-types';
import _, { map, noop, orderBy } from 'lodash';
import {
  Box,
  BaseDrawer,
  useDebouncedCallback,
  TotalLayoutContainer,
  TotalLayoutStepContainer,
  Stack,
  LoadingOverlay,
} from '@bubbles-ui/components';
import { useStore } from '@common';
import useRequestErrorMessage from '@common/useRequestErrorMessage';
import ChatInfoDrawer from '@comunica/components/ChatInfoDrawer/ChatInfoDrawer';
import getRoomParsed from '@comunica/helpers/getRoomParsed';
import isStudentsChatRoom from '@comunica/helpers/isStudentsChatRoom';
import isStudentTeacherChatRoom from '@comunica/helpers/isStudentTeacherChatRoom';
import isTeacherByRoom from '@comunica/helpers/isTeacherByRoom';
import prefixPN from '@comunica/helpers/prefixPN';
import RoomService from '@comunica/RoomService';
import { addErrorAlert } from '@layout/alert';
import SocketIoService from '@mqtt-socket-io/service';
import useTranslateLoader from '@multilanguage/useTranslateLoader';
import { getCentersWithToken } from '@users/session';
import {
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  CHAT_ROOM_SUBJECT_TEACHERS_TYPE,
  EVENT_CONFIG_CENTER,
  EVENT_CONFIG_PROGRAM,
  EVENT_CONFIG_ROOM,
  EVENT_ROOM_ADMIN_DISABLE_MESSAGES,
  EVENT_ROOM_ADMIN_MUTED,
  EVENT_ROOM_REMOVE,
  EVENT_ROOM_UPDATE_IMAGE,
  EVENT_ROOM_UPDATE_NAME,
  EVENT_ROOM_USER_ADDED,
  EVENT_ROOM_USERS_REMOVED,
  MESSAGE_TYPE_IMG,
  MESSAGE_TYPE_TEXT,
} from '@comunica/constants';
import { ChatDrawerStyles } from './ChatDrawer.styles';
import { ChatDrawerFooter } from './ChatDrawerFooter/ChatDrawerFooter';
import { ChatDrawerHeader } from './ChatDrawerHeader/ChatDrawerHeader';
import { ChatDrawerMessageList } from './ChatDrawerMessageList/ChatDrawerMessageList';

function ChatDrawer({
  room,
  subjectRooms,
  opened,
  onReturn,
  selectedMessage,
  onClose = noop,
  onMessage = noop,
  onRoomLoad = noop,
  onMessagesMarkAsRead = noop,
}) {
  const { classes } = ChatDrawerStyles({}, { name: 'ChatDrawer' });
  const [t] = useTranslateLoader(prefixPN('chatDrawer'));
  const [td] = useTranslateLoader(prefixPN('chatListDrawer'));
  const [, , , getErrorMessage] = useRequestErrorMessage();
  const [isLoading, setIsLoading] = React.useState(false);
  const debouncedFunction = useDebouncedCallback(300);
  const scrollRef = React.useRef();
  const [store, render] = useStore({
    selectedMessage: null,
    searchMode: false,
    showMembers: false,
    canShowSelected: true,
    canShowSelectedTimeout: null,
  });

  function scrollToBottom() {
    if (scrollRef.current)
      scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  }

  function scrollToMessage(id) {
    document.getElementById(id)?.scrollIntoView();
  }

  async function updateUserAgentsConnected() {
    let userAgentIds = [];
    _.forEach(store.room?.userAgents, (userAgent) => {
      userAgentIds.push(userAgent.userAgent.id);
    });
    userAgentIds = _.uniq(userAgentIds);
    const { userAgentsConnected } = await RoomService.getConnectedStatus({
      userAgentIds,
    });
    _.forEach(store.room?.userAgents, (__, i) => {
      const userAgent = store.room.userAgents[i];
      userAgent.connected =
        userAgentsConnected.indexOf(userAgent.userAgent.id) > -1;
    });
    render();
  }

  function startClearSelected() {
    store.canShowSelectedTimeout = setTimeout(() => {
      store.canShowSelectedTimeout = null;
      store.canShowSelected = false;
      render();
    }, 4000);
  }

  async function getTenorApiKeyIfNeed() {
    if (!store.tenorApiKey) {
      store.tenorApiKey = await RoomService.getTenorApiKey();
      render();
    }
  }

  async function load() {
    store.canShowSelected = true;
    store.userAgent = getCentersWithToken()[0].userAgentId;
    store.room = getRoomParsed(await store.service.getRoom());

    getTenorApiKeyIfNeed();
    await updateUserAgentsConnected();
    store.programConfig = null;
    if (store.room?.program) {
      store.programConfig = await RoomService.getProgramConfig(
        store.room.program
      );
    }
    if (!store.config) {
      store.config = await RoomService.getConfig();
    }
    store.messages = await store.service.getRoomMessages();
    store.messages = orderBy(store.messages, 'createdAt', 'asc');
    store.messages = map(store.messages, (message) => ({
      ...message,
      createdAt: new Date(message.createdAt),
    }));
    store.userAgentsById = _.keyBy(
      _.map(store.room.userAgents, 'userAgent'),
      'id'
    );

    render();
    onRoomLoad(store.room);
    setTimeout(() => {
      if (!store.selectedMessage) {
        scrollToBottom();
      } else {
        scrollToMessage(store.selectedMessage.id);
      }
    }, 10);
    startClearSelected();
    setIsLoading(false);
  }

  async function focusSwitch() {
    await RoomService.saveConfig({ muted: !store.config.muted });
    store.config.muted = !store.config.muted;
    render();
  }

  async function toggleMute() {
    store.room.muted = !store.room.muted;
    render();
    const { muted } = await store.service.toggleRoomMute();
    if (store.room.muted !== muted) {
      store.room.muted = muted;
      render();
    }
  }

  async function toggleAttached() {
    store.room.attached = store.room.attached ? null : new Date();
    render();
    await store.service.toggleRoomAttached();
  }

  function toggleInfo() {
    store.showInfo = !store.showInfo;
    render();
  }

  function toggleSearch() {
    store.searchMode = !store.searchMode;
    render();
  }

  function cancelSearch() {
    store.selectedMessage = null;
    store.searchMode = false;
    render();
  }

  function searchChange(value) {
    store.searchFound = [];
    if (!store.selectedMessage) store.selectedMessage = {};
    store.selectedMessage.query = value;
    render();
  }

  function searchReturnMessages() {
    const searchFound = [];
    if (store.selectedMessage?.query) {
      const messagesFound = [];
      _.forEach(store.messages, (message) => {
        if (
          message.message.type === MESSAGE_TYPE_TEXT &&
          message.message.content
            .toLowerCase()
            .includes(store.selectedMessage.query.toLowerCase())
        ) {
          messagesFound.push(message);
        }
      });
      _.forEach(messagesFound, (message) => {
        const element = document.getElementById(message.id);
        if (element) {
          searchFound.push({
            top: element.getBoundingClientRect().top,
            element,
            message,
          });
        }
      });
    }
    return searchFound;
  }

  function selectedMessageIndex() {
    const messagesFound = searchReturnMessages();
    if (messagesFound.length) {
      return (
        _.findIndex(
          messagesFound,
          (m) => m.message.id === store.selectedMessage.id
        ) + 1
      );
    }
    return 0;
  }

  function searchGoUp() {
    const messagesFound = searchReturnMessages();
    if (messagesFound.length) {
      // Ordenar mensajes por posición de arriba a abajo
      messagesFound.sort((a, b) => a.top - b.top);

      const currentIndex = messagesFound.findIndex(
        (m) => m.message.id === store.selectedMessage?.id
      );
      let nextIndex;

      if (currentIndex === -1 || currentIndex === 0) {
        // Si no hay selección actual o estamos en el primer mensaje, ir al último
        nextIndex = messagesFound.length - 1;
      } else {
        // Ir al mensaje anterior
        nextIndex = currentIndex - 1;
      }

      store.selectedMessage = {
        ...store.selectedMessage,
        id: messagesFound[nextIndex].message.id,
      };
      store.canShowSelected = true;
      scrollToMessage(store.selectedMessage.id);
      clearTimeout(store.canShowSelectedTimeout);
      startClearSelected();
      render();
    }
  }

  function searchGoDown() {
    const messagesFound = searchReturnMessages();
    if (messagesFound.length) {
      const positiveTop = _.filter(
        messagesFound,
        (m) => m.top > 50 && m.message.id !== store.selectedMessage.id
      );
      if (positiveTop.length) {
        // Ordenamos de menor a mayor
        positiveTop.sort((a, b) => b.top + a.top);
        store.selectedMessage.id = positiveTop[0].message.id;
        store.canShowSelected = true;
        scrollToMessage(store.selectedMessage.id);
        clearTimeout(store.canShowSelectedTimeout);
        startClearSelected();
        render();
      }
    }
  }

  function closeInfo() {
    toggleInfo();
    onClose();
  }

  async function sendMessage() {
    try {
      if (store.newMessage && !store.sendingMessage) {
        store.sendingMessage = true;
        await store.service.sendMessageToRoom({
          type: MESSAGE_TYPE_TEXT,
          content: store.newMessage,
        });
        store.newMessage = '';
        render();
      }
    } catch (err) {
      addErrorAlert(getErrorMessage(err));
    }
    store.sendingMessage = false;
  }

  async function sendImageMessage(url) {
    try {
      if (url && !store.sendingMessage) {
        store.sendingMessage = true;
        await store.service.sendMessageToRoom({
          type: MESSAGE_TYPE_IMG,
          content: url,
        });
        store.newMessage = '';
        render();
      }
    } catch (err) {
      addErrorAlert(getErrorMessage(err));
    }
    store.sendingMessage = false;
  }

  React.useEffect(() => {
    if (room) {
      setIsLoading(true);
      store.service = new RoomService(room);
      load();
    }
  }, [room]);

  React.useEffect(() => {
    setTimeout(() => {
      if (opened) {
        scrollToBottom();
        store.service?.markRoomMessagesAsRead();
        onMessagesMarkAsRead();
      } else {
        clearTimeout(store.canShowSelectedTimeout);
      }
    }, 10);
  }, [opened]);

  React.useEffect(() => {
    updateUserAgentsConnected();
    const interval = setInterval(() => {
      updateUserAgentsConnected();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    store.selectedMessage = selectedMessage;
    render();
  }, [JSON.stringify(selectedMessage)]);

  RoomService.watchRoom(room, (roomData) => {
    let _scrollToBottom = false;
    if (scrollRef.current) {
      const scrolled =
        scrollRef.current.scrollTop + scrollRef.current.clientHeight;
      if (scrolled > scrollRef.current.scrollHeight - 50) {
        _scrollToBottom = true;
      }
    }
    store.messages.push({
      ...roomData,
      createdAt: new Date(roomData.createdAt),
    });
    onMessage(roomData);
    render();

    if (opened) {
      store.service?.markRoomMessagesAsRead();
      onMessagesMarkAsRead();
    }

    if (_scrollToBottom) {
      setTimeout(
        () => {
          if (scrollRef.current)
            scrollRef.current.scrollTo({
              top: scrollRef.current.scrollHeight,
              behavior: 'smooth',
            });
        },
        roomData.message.type === MESSAGE_TYPE_IMG ? 1000 : 10
      );
    }
  });

  function returnOrClose() {
    if (_.isFunction(onReturn)) {
      onReturn();
    } else {
      onClose();
    }
  }

  function insertEmoji(emoji) {
    if (!store.newMessage) store.newMessage = '';
    store.newMessage += emoji.native;
    render();
  }

  SocketIoService.useOn(EVENT_CONFIG_CENTER, (event, eventData) => {
    if (eventData.center === getCentersWithToken()[0].id && store.room) {
      if (
        !eventData.config.enableStudentsChats &&
        isStudentsChatRoom(store.room)
      ) {
        returnOrClose();
      }
      if (
        eventData.config?.disableChatsBetweenStudentsAndTeachers &&
        isStudentTeacherChatRoom(store.room)
      ) {
        returnOrClose();
      }
    }
  });

  SocketIoService.useOn(EVENT_CONFIG_PROGRAM, (event, eventData) => {
    if (store.room?.program === eventData.program) {
      store.programConfig = eventData.config;
    }
    if (
      store.room?.type === CHAT_ROOM_SUBJECT_ALL_TYPE &&
      store.room?.program === eventData.program &&
      !eventData.config.enableSubjectsRoom
    ) {
      returnOrClose();
    }
  });

  SocketIoService.useOn(EVENT_CONFIG_ROOM, (event, eventData) => {
    if (store.room?.key === eventData.room) {
      store.room.muted = !!eventData.muted;
      store.room.attached = eventData.attached;
      store.room.adminMuted = eventData.adminMuted;
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_REMOVE, (event, eventData) => {
    if (store.room?.key === eventData.key) {
      store.room = null;
      if (_.isFunction(onReturn)) {
        onReturn();
      } else {
        onClose();
      }
    }
  });

  SocketIoService.useOn(EVENT_ROOM_USER_ADDED, (event, eventData) => {
    if (store.room?.key === eventData.key) {
      const index = _.findIndex(
        store.room.userAgents,
        (item) => item.userAgent.id === eventData.userAgent.userAgent.id
      );
      if (index >= 1) {
        store.room.userAgents[index] = eventData.userAgent;
      } else {
        store.room.userAgents.push(eventData.userAgent);
      }
      store.room = getRoomParsed(store.room);
      debouncedFunction(render);
    }
  });

  SocketIoService.useOn(EVENT_ROOM_USERS_REMOVED, (event, eventData) => {
    if (store.room?.key === eventData.key) {
      store.room.userAgents = _.map(store.room.userAgents, (item) => {
        let { deleted } = item;
        if (eventData.userAgents.includes(item.userAgent.id)) deleted = true;
        return {
          ...item,
          deleted,
        };
      });
      store.room = getRoomParsed(store.room);
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_UPDATE_IMAGE, (event, eventData) => {
    if (store.room?.key === eventData.key) {
      store.room.image = eventData.image;
      if (!store.room.imageSeed) store.room.imageSeed = 0;
      store.room.imageSeed++;
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_UPDATE_NAME, (event, eventData) => {
    if (store.room?.key === eventData.key) {
      store.room.name = eventData.name;
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_ADMIN_MUTED, (event, eventData) => {
    if (store.room?.key === eventData.room) {
      const index = _.findIndex(
        store.room.userAgents,
        (item) => item.userAgent.id === eventData.userAgent
      );
      if (index >= 0) {
        store.room.userAgents[index].adminMuted = eventData.adminMuted;
        store.room.userAgents = [...store.room.userAgents];
        render();
      }
    }
  });

  SocketIoService.useOn(
    EVENT_ROOM_ADMIN_DISABLE_MESSAGES,
    (event, eventData) => {
      if (store.room?.key === eventData.room) {
        store.room.adminDisableMessages = eventData.adminDisableMessages;
        render();
      }
    }
  );

  let canWrite = !store.room?.adminMuted;
  if (
    canWrite &&
    store.room?.type === CHAT_ROOM_SUBJECT_ALL_TYPE &&
    store.programConfig?.onlyTeachersCanWriteInSubjectsRooms
  ) {
    canWrite = isTeacherByRoom(store.room);
  }
  if (canWrite && store.room?.adminDisableMessages && !store.room?.isAdmin) {
    canWrite = false;
  }

  let canAttach = true;
  if (
    (store.room?.type.startsWith('assignable') ||
      store.room?.type === CHAT_ROOM_SUBJECT_TEACHERS_TYPE) &&
    isTeacherByRoom(store.room)
  ) {
    canAttach = false;
  }

  const menuItems = React.useMemo(() => {
    const m = [
      {
        children: t('focusMode'),
        onClick: focusSwitch,
      },
      {
        children: t('search'),
        onClick: toggleSearch,
      },
      {
        children: store.room?.muted ? t('unmuteRoom') : t('muteRoom'),
        onClick: toggleMute,
      },
    ];
    if (canAttach) {
      m.push({
        children: store.room?.attached ? t('unsetRoom') : t('setRoom'),
        onClick: toggleAttached,
      });
    }
    return m;
  }, [store.room?.muted, store.room?.attached, canAttach, t]);

  return (
    <>
      <BaseDrawer
        opened={opened}
        size={400}
        close={false}
        className={classes.drawer}
        empty
      >
        <Box className={classes.wrapper}>
          <TotalLayoutContainer
            scrollRef={scrollRef}
            Header={
              <ChatDrawerHeader
                t={t}
                td={td}
                room={store.room}
                subjectRooms={subjectRooms}
                searchMode={store.searchMode}
                selectedMessage={store.selectedMessage}
                menuItems={menuItems}
                toggleInfo={toggleInfo}
                onReturn={onReturn}
                onClose={onClose}
                onSearchChange={searchChange}
                onSearchGoUp={searchGoUp}
                onSearchGoDown={searchGoDown}
                onCancelSearch={cancelSearch}
                searchCount={searchReturnMessages().length}
                searchCountIndex={selectedMessageIndex()}
              />
            }
            Footer={
              !!canWrite && (
                <ChatDrawerFooter
                  t={t}
                  tenorApiKey={store.tenorApiKey}
                  message={store.newMessage}
                  onMessageChange={(e) => {
                    store.newMessage = e;
                    render();
                  }}
                  onSendMessage={sendMessage}
                  onSendImageMessage={sendImageMessage}
                  onInsertEmoji={insertEmoji}
                />
              )
            }
          >
            <Stack ref={scrollRef} style={{ overflow: 'auto' }} fullWidth>
              <TotalLayoutStepContainer noMargin fullWidth clean>
                <Box ref={scrollRef} className={classes.messages}>
                  <LoadingOverlay visible={isLoading} />
                  {!isLoading && (
                    <ChatDrawerMessageList
                      t={t}
                      room={store.room}
                      messages={store.messages}
                      userAgent={store.userAgent}
                      userAgentsById={store.userAgentsById}
                      selectedMessage={store.selectedMessage}
                      canShowSelected={store.canShowSelected}
                    />
                  )}
                </Box>
              </TotalLayoutStepContainer>
            </Stack>
          </TotalLayoutContainer>
        </Box>
      </BaseDrawer>

      {!!store.room && (
        <ChatInfoDrawer
          room={store.room}
          opened={store.showInfo}
          onReturn={toggleInfo}
          onClose={closeInfo}
        />
      )}
    </>
  );
}

ChatDrawer.propTypes = {
  room: PropTypes.string,
  subjectRooms: PropTypes.array,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  onReturn: PropTypes.func,
  onMessage: PropTypes.func,
  onRoomLoad: PropTypes.func,
  selectedMessage: PropTypes.any,
  onMessagesMarkAsRead: PropTypes.func,
};

export { ChatDrawer };
export default ChatDrawer;
