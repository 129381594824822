import {
  createStyles,
  getBoxShadowFromToken,
  getFocusDefaultBorder,
} from '@bubbles-ui/components';

const TRANSLATE_CENTER = 'translate(-50%, -50%)';

const ChatHubStyles = createStyles((theme) => {
  const { background, content } = theme.other.global;
  const { button } = theme.other;

  return {
    root: {
      position: 'fixed',
      zIndex: 55,
      bottom: 85, // hasFooter ? 16 : 16
      right: 14,
      '@media (min-width: 1280px)': {
        bottom: 16,
        right: 14,
      },
    },
    chatBullet: {
      width: 44,
      height: 44,
      backgroundColor: background.color.primary.default,
      borderRadius: '50%',
      cursor: 'pointer',
      '&:focus-visible': {
        backgroundColor: button.background.color.primary.hover,
        ...getFocusDefaultBorder(theme),
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: button.background.color.primary.hover,
        ...getBoxShadowFromToken(button.shadow.hover),
      },
      '&:active': {
        backgroundColor: button.background.color.primary.pressed,
        boxShadow: 'none',
      },
    },
    chatIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      width: 24,
      height: 24,
      color: button.content.color.primary.default,
      transform: TRANSLATE_CENTER,
    },
    unreadMessages: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: TRANSLATE_CENTER,
      textAlign: 'center',
      display: 'inline-flex',
      color: 'black',
      ...content.typoMobile.body['sm--bold'],
      '&:after': {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: TRANSLATE_CENTER,
        backgroundColor: background.color.primary.default,
        display: 'block',
        content: '""',
        width: 18,
        height: 16,
        borderRadius: '50%',
        zIndex: -1,
      },
    },
  };
});

export { ChatHubStyles };
