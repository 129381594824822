import { createStyles } from '@bubbles-ui/components'; // eslint-disable-next-line import/prefer-default-export

// eslint-disable-next-line import/prefer-default-export
export const ChatListDrawerItemStyles = createStyles((theme, { room }) => ({
  item: {
    paddingInline: theme.spacing[5],
    paddingBlock: theme.spacing[3],
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing[4],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.other.global.background.color.surface.muted,
    },
  },
  itemTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing[1],
    paddingRight: theme.spacing[4],
    width: '100%',
    minHeight: 38,
    justifyContent: 'center',
  },
  itemIcons: {
    display: 'flex',
    alignItems: 'center',
    color: theme.other.global.content.color.icon.default,
    gap: theme.spacing[2],
    marginBottom: '3px',
  },
  itemContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    overflow: 'hidden',
  },
  name: {
    fontFamily: 'Albert Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    color: theme.other.global.content.color.text.emphasis,
    maxWidth: 210,
  },
  subName: {
    fontFamily: 'Albert Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.other.global.content.color.text.emphasis,
    maxWidth: 210,
  },
  lastMessage: {
    fontFamily: 'Albert Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.other.global.content.color.text.default,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp':
      room.type === 'chat' || room.type === 'chat-parent' ? '2' : '1',
    '-webkitBoxOrient': 'vertical',
  },
  unreadMessages: {
    width: 16,
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.other.global.content.color.icon.default,
    color: theme.other.badge.content.color['default--reverse'],
    borderRadius: '50%',
    fontSize: 10,
    fontWeight: 500,
    lineHeight: '14px',
    fontFamily: 'Albert Sans',
  },
  date: {
    fontFamily: 'Albert Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.other.global.content.color.text.emphasis,
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));
