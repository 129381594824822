import React from 'react';
import PropTypes from 'prop-types';
import _, { noop } from 'lodash';
import {
  ActionButton,
  Box,
  Button,
  BaseDrawer,
  ImageProfilePicker,
  Switch,
  TextInput,
  UserDisplayItem,
} from '@bubbles-ui/components';
import {
  ChevDownIcon,
  ChevronLeftIcon,
  ChevUpIcon,
  RemoveIcon,
} from '@bubbles-ui/icons/outline';
import {
  DeleteBinIcon,
  FavoriteHeartIcon,
  VolumeControlOffIcon,
} from '@bubbles-ui/icons/solid';
import { useStore } from '@common';
import ChatAddUsersDrawer from '@comunica/components/ChatAddUsersDrawer/ChatAddUsersDrawer';
import prefixPN from '@comunica/helpers/prefixPN';
import RoomService from '@comunica/RoomService';
import getBase64 from '@leebrary/helpers/getBase64';
import SocketIoService from '@mqtt-socket-io/service';
import useTranslateLoader from '@multilanguage/useTranslateLoader';
import getUserAgentsInfo from '@users/request/getUserAgentsInfo';
import { getSessionCenter } from '@users/session';
import {
  CHAT_ROOM_GROUP_TYPE,
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  EVENT_CONFIG_PROGRAM,
} from '@comunica/constants';
import { getAssetUrl } from '@leebrary/helpers/prepareAsset';
import compressImage from '@leebrary/helpers/compressImage';
import { useLayout } from '@layout/context';
import { ChatInfoDrawerStyles } from './ChatInfoDrawer.styles';
import { RoomAvatar } from '../RoomAvatar/RoomAvatar';

const usersToShow = 7;

function ChatInfoDrawer({
  room,
  opened,
  disabledProfiles,
  program,
  onBeforeNewGroup = noop,
  onAfterNewGroup = noop,
  onReturn = noop,
  onClose = noop,
}) {
  const { classes } = ChatInfoDrawerStyles({}, { name: 'ChatDrawer' });
  const [t] = useTranslateLoader(prefixPN('chatListDrawer'));
  const { openDeleteConfirmationModal } = useLayout();
  const center = getSessionCenter();

  const [store, render] = useStore({
    showMembers: false,
    name: room ? room.name : null, // t(room.name, room.nameReplaces, null, room.name)
    createFile: room?.image,
    muted: room?.muted || false,
    attached: room?.attached || false,
    adminDisableMessages: room?.adminDisableMessages || false,
    createUserAgents: [],
  });

  async function toggleMute() {
    store.muted = !store.muted;
    render();
    if (room) {
      const { muted } = await RoomService.toggleRoomMute(room.key);
      if (store.muted !== muted) {
        store.muted = muted;
        render();
      }
    }
  }

  function reset() {
    store.adminDisableMessages = false;
    store.attached = false;
    store.muted = false;
    store.showAllMembers = false;
    store.name = null;
    store.createUserAgents = [];
    store.nameError = false;
    store.createFile = null;
    store.file = null;
  }

  async function toggleAttached() {
    store.attached = store.attached ? null : new Date();
    render();
    if (room) await RoomService.toggleRoomAttached(room.key);
  }

  async function toggleAdminDisableMessages() {
    store.adminDisableMessages = !store.adminDisableMessages;
    render();
    if (room) await RoomService.adminDisableMessages(room.key);
  }

  function toggleShowAllMembers() {
    store.showAllMembers = !store.showAllMembers;
    render();
  }

  function deleteUserFromRoom(userAgent) {
    if (room) {
      RoomService.adminRemoveUserAgentFromRoom(room.key, userAgent.id);
    } else {
      const index = _.findIndex(store.createUserAgents, { id: userAgent.id });
      if (index >= 0) {
        store.createUserAgents.splice(index, 1);
        render();
      }
    }
  }

  function muteAdminUserFromRoom(userAgent) {
    RoomService.toggleAdminRoomMute(room.key, userAgent.id);
  }

  function onNameChange(name) {
    store.name = name;
    store.nameError = !store.name;
    render();
  }

  async function updateName() {
    try {
      store.saving = true;
      render();
      await RoomService.adminUpdateRoomName(room.key, store.name);
      onReturn();
    } catch (error) {
      console.log('Error updating room name:', error);
    } finally {
      store.saving = false;
      render();
    }
  }

  function closeAddUsers() {
    store.showAddUsers = false;
    render();
    onClose();
  }

  function addUsers(e) {
    store.createUserAgents = [...store.createUserAgents, ...e];
    render();
  }

  function returnAddUsers() {
    store.showAddUsers = false;
    render();
  }

  function openAddUsers() {
    store.showAddUsers = true;
    render();
  }

  function removeRoom() {
    openDeleteConfirmationModal({
      onConfirm: async () => {
        try {
          store.saving = true;
          render();
          await RoomService.adminRemoveRoom(room.key);
        } catch (error) {
          console.log('Error removing room:', error);
        } finally {
          store.saving = false;
          render();
        }
      },
    })();
  }

  async function createGroup() {
    onBeforeNewGroup();
    const { room: newRoom } = await RoomService.createRoom({
      name: store.name,
      type: CHAT_ROOM_GROUP_TYPE,
      program: program || room?.program,
      userAgents: _.map(store.createUserAgents, 'id'),
      icon: '/public/comunica/group.svg',
      bgColor: '#878D96',
      metadata: {
        iconIsUrl: true,
      },
    });
    if (store.adminDisableMessages)
      RoomService.adminDisableMessages(newRoom.key);
    if (store.attached) RoomService.toggleRoomAttached(newRoom.key);
    if (store.muted) RoomService.toggleRoomMute(newRoom.key);
    if (store.file) RoomService.adminChangeRoomImage(newRoom.key, store.file);

    onAfterNewGroup(newRoom);
    reset();
    render();
  }

  async function onImageClick(file) {
    if (file === null) {
      RoomService.adminRemoveRoomImage(room.key);
      return;
    }

    let compressedFile = file;
    if (
      file.type.startsWith('image') &&
      file.type.indexOf('/gif') < 0 &&
      file.type.indexOf('/svg') < 0
    ) {
      compressedFile = await compressImage({
        file: new File([file], file.name, { type: file.type }),
      });
    }

    if (!room) {
      store.createFile = await getBase64(file);
      store.file = file;
      render();
    } else {
      RoomService.adminChangeRoomImage(room.key, compressedFile);
    }
  }

  async function load() {
    const {
      userAgents: [item],
    } = await getUserAgentsInfo(center?.userAgentId, {
      withProfile: true,
    });
    store.me = item;
    render();
  }

  async function loadConfig() {
    store.programConfig = null;
    if (room?.program) {
      store.programConfig = await RoomService.getProgramConfig(room?.program);
    }
    render();
  }

  function beforeReturn() {
    onReturn();
    if (!room) {
      reset();
      render();
    }
  }

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    store.name = room?.name ?? null;
    store.createFile = room?.image ?? null;
    render();
  }, [room]);

  React.useEffect(() => {
    loadConfig();
  }, [room?.id]);

  React.useEffect(() => {
    if (room && store.muted !== room.muted) {
      store.muted = room.muted;
      render();
    }
  }, [room?.muted]);

  React.useEffect(() => {
    if (room && store.adminDisableMessages !== room.adminDisableMessages) {
      store.adminDisableMessages = room.adminDisableMessages;
      render();
    }
  }, [room?.adminDisableMessages]);

  React.useEffect(() => {
    if (room && store.attached !== room.attached) {
      store.attached = room.attached;
      render();
    }
  }, [room?.attached]);

  let headerRoom = room;
  if (!room) {
    headerRoom = {
      name: store.name || t('newGroupName'),
      type: CHAT_ROOM_GROUP_TYPE,
      imageIsUrl: true,
      image: store.createFile,
      userAgents: _.map(store.createUserAgents, (item) => ({
        userAgent: item,
        deleted: false,
      })),
    };
    if (store.me) {
      headerRoom.userAgents.unshift({
        deleted: false,
        isAdmin: true,
        userAgent: store.me,
      });
    }
  }

  if (headerRoom?.userAgents) {
    store.userAgents = _.filter(headerRoom?.userAgents, (e) => !e.deleted);
    store.nNoDeletedAgents = store.userAgents.length;
    if (!store.showAllMembers) {
      store.userAgents = store.userAgents.slice(0, usersToShow);
    }
  }

  let saveDisabled =
    store.name === room?.name || store.nameError || !store.name;
  if (!room && headerRoom.userAgents.length < 2) saveDisabled = true;

  SocketIoService.useOn(EVENT_CONFIG_PROGRAM, (event, eventData) => {
    if (room?.program === eventData.program) {
      store.programConfig = eventData.config;
      render();
    }
  });

  return (
    <>
      <BaseDrawer opened={opened} size={400} close={false} empty>
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Button
              variant="link"
              color="secondary"
              onClick={beforeReturn}
              leftIcon={<ChevronLeftIcon />}
              iconSize={8}
            >
              {t('returnToGroup')}
            </Button>
            <ActionButton
              onClick={onClose}
              icon={<RemoveIcon width={16} height={16} />}
            />
          </Box>

          <Box className={classes.content}>
            {(!room ||
              (room?.isAdmin && room?.type === CHAT_ROOM_GROUP_TYPE)) && (
              <>
                <Box className={classes.newGroupTitle}>
                  {!room ? t('newGroup') : t('editGroup')}
                </Box>
                <Box className={classes.imageWrapper}>
                  <ImageProfilePicker
                    url={getAssetUrl(headerRoom.image)}
                    fullName={store.name}
                    onChange={onImageClick}
                    labels={{
                      uploadImage: t('uploadImage'),
                      changeImage: t('changeImage'),
                      delete: t('deleteImage'),
                      cancel: t('cancelImage'),
                      accept: t('acceptImage'),
                    }}
                  />
                </Box>
                <Box className={classes.name}>
                  <TextInput
                    required
                    label={t('groupName')}
                    error={store.nameError ? t('nameRequired') : null}
                    value={store.name}
                    onChange={onNameChange}
                  />
                </Box>
              </>
            )}

            {!!room && !room?.isAdmin && (
              <>
                <Box className={classes.newGroupTitle}>
                  {!room ? t('newGroup') : t('editGroup')}
                </Box>
                <Box className={classes.imageWrapper}>
                  <RoomAvatar room={room} size={120} />
                </Box>
                <Box className={classes.groupTitle}>
                  {store.name}

                  {room.attached ? (
                    <Box sx={{ display: 'flex', color: '#FF5470' }}>
                      <FavoriteHeartIcon />
                    </Box>
                  ) : null}
                </Box>
              </>
            )}

            {store.programConfig?.teachersCanDisableSubjectsRooms &&
              room?.type === CHAT_ROOM_SUBJECT_ALL_TYPE &&
              room?.isAdmin && (
                <Switch
                  checked={!!store.adminDisableMessages}
                  onChange={toggleAdminDisableMessages}
                  label={t('adminDisableMessages')}
                />
              )}

            <Box className={classes.participants}>
              {t('participants')} ({store.nNoDeletedAgents})
            </Box>
            {store.userAgents?.map((item) => (
              <Box key={item.userAgent.id} className={classes.userInfo}>
                <UserDisplayItem {...item.userAgent.user} size="xs" />
                {item.isAdmin && (
                  <Box className={classes.userAdmin}>{t('admin')}</Box>
                )}
                {!item.isAdmin && (!room || room?.isAdmin) && (
                  <Box className={classes.adminIcons}>
                    {room &&
                      (!store.programConfig ||
                        item.adminMuted ||
                        store.programConfig.teachersCanMuteStudents) && (
                        <Box
                          className={
                            item.adminMuted
                              ? classes.userMuteIconActive
                              : classes.userMuteIcon
                          }
                        >
                          <ActionButton
                            onClick={() =>
                              muteAdminUserFromRoom(item.userAgent)
                            }
                            icon={
                              <VolumeControlOffIcon width={16} height={16} />
                            }
                          />
                        </Box>
                      )}

                    {room?.type === CHAT_ROOM_GROUP_TYPE && (
                      <Box className={classes.userRemove}>
                        <ActionButton
                          color="phatic"
                          onClick={() => deleteUserFromRoom(item.userAgent)}
                          icon={<DeleteBinIcon width={16} height={16} />}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            ))}
            {headerRoom?.userAgents.length > usersToShow && (
              <Box onClick={toggleShowAllMembers} className={classes.showAll}>
                {store.showAllMembers ? (
                  <>
                    <ChevUpIcon /> {t('showLess')}
                  </>
                ) : (
                  <>
                    <ChevDownIcon /> {t('showAll')}
                  </>
                )}
              </Box>
            )}

            {(!room ||
              (room?.isAdmin && room?.type === CHAT_ROOM_GROUP_TYPE)) && (
              <Box onClick={openAddUsers} className={classes.showAll}>
                + {t('addNewUsers')}
              </Box>
            )}
          </Box>
          {(!room ||
            (room?.isAdmin && room?.type === CHAT_ROOM_GROUP_TYPE)) && (
            <Box className={classes.buttonActions}>
              {!!room && (
                <Button onClick={removeRoom} variant="outline">
                  {t('remove')}
                </Button>
              )}

              <Button
                disabled={saveDisabled || store.saving}
                loading={store.saving}
                onClick={room ? updateName : createGroup}
              >
                {t('save')}
              </Button>
            </Box>
          )}
        </Box>
      </BaseDrawer>
      <ChatAddUsersDrawer
        room={headerRoom}
        program={program || room?.program}
        disabledProfiles={disabledProfiles}
        onSave={room ? null : addUsers}
        opened={store.showAddUsers}
        onClose={closeAddUsers}
        onReturn={returnAddUsers}
      />
    </>
  );
}

ChatInfoDrawer.propTypes = {
  room: PropTypes.object,
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  program: PropTypes.string,
  onReturn: PropTypes.func,
  onBeforeNewGroup: PropTypes.func,
  onAfterNewGroup: PropTypes.func,
  disabledProfiles: PropTypes.array,
};

export { ChatInfoDrawer };
export default ChatInfoDrawer;
