import React from 'react';

import { Box, createStyles, Text } from '@bubbles-ui/components';
import PropTypes from 'prop-types';

import { useRoomsMessageCount } from '../hooks';

export const UnreadMessagesStyles = createStyles((theme) => ({
  icon: {
    width: 30,
    height: 30,
    background: theme.other.button.background.color.primary.default,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unreadCountText: {
    color: theme.other.global.content.color.text.default,
  },
  text: {
    color: theme.other.global.content.color.text.subtle,
    lineHeight: '1em',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing[2],
  },
}));

export function UnreadMessages({ rooms }) {
  const { unread, count } = useRoomsMessageCount(rooms);

  const { classes } = UnreadMessagesStyles({}, { name: 'UnreadMessages' });

  if (!count) {
    return <Text className={classes.text}>-</Text>;
  }

  return (
    <Box className={classes.root}>
      {!!unread && (
        <Box className={classes.icon}>
          <Text strong className={classes.unreadCountText}>
            {unread > 99 ? '+99' : unread}
          </Text>
        </Box>
      )}
      <Text className={classes.text}>({count > 999 ? '+999' : count})</Text>
    </Box>
  );
}

UnreadMessages.propTypes = {
  rooms: PropTypes.any,
};

export default UnreadMessages;
