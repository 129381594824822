// Rooms without context
export const CHAT_ROOM_GROUP_TYPE = 'group';
export const CHAT_ROOM_1ON1_TYPE = 'chat';
export const CHAT_ROOM_1ON1_PARENT_TYPE = 'chat-parent';

// Subject hierarchy
export const GROUPED_ROOMS_SUBJECT_TYPE = 'academic-portfolio.class.group';
export const CHAT_ROOM_SUBJECT_ALL_TYPE = 'academic-portfolio.class';
export const CHAT_ROOM_SUBJECT_TEACHERS_TYPE =
  'academic-portfolio.class.student-teachers';

// Activity hierarchy
export const GROUPED_ROOMS_ACTIVITY_TYPE = 'assignables.assignation';
export const CHAT_ROOM_ACTIVITY_1ON1_TYPE = 'assignables.assignation.user';
export const CHAT_ROOM_ACTIVITY_ALL_TYPE = 'assignables.assignation.group';
export const CHAT_ROOM_ACTIVITY_SUBJECT_TYPE =
  'assignables.assignation.subject';

// Type messages
export const MESSAGE_TYPE_TEXT = 'text';
export const MESSAGE_TYPE_IMG = 'img';

// Events
export const EVENT_CONFIG = 'COMUNICA:CONFIG';
export const EVENT_CONFIG_GENERAL = 'COMUNICA:CONFIG:GENERAL';
export const EVENT_CONFIG_CENTER = 'COMUNICA:CONFIG:CENTER';
export const EVENT_CONFIG_PROGRAM = 'COMUNICA:CONFIG:PROGRAM';
export const EVENT_CONFIG_ROOM = 'COMUNICA:CONFIG:ROOM';
export const EVENT_ROOM_REMOVE = 'COMUNICA:ROOM:REMOVE';
export const EVENT_ROOM_ADDED = 'COMUNICA:ROOM:ADDED';
export const EVENT_ROOM_USER_ADDED = 'COMUNICA:ROOM:USER_ADDED';
export const EVENT_ROOM_USERS_REMOVED = 'COMUNICA:ROOM:USERS_REMOVED';
export const EVENT_ROOM_UPDATE_IMAGE = 'COMUNICA:ROOM:UPDATE:IMAGE';
export const EVENT_ROOM_UPDATE_NAME = 'COMUNICA:ROOM:UPDATE:NAME';
export const EVENT_ROOM_ADMIN_MUTED = 'COMUNICA:ROOM:ADMIN_MUTED';
export const EVENT_ROOM_ADMIN_DISABLE_MESSAGES =
  'COMUNICA:ROOM:ADMIN_DISABLE_MESSAGES';

// User types
export const USER_TYPE_ADMIN = 'admin';
export const USER_TYPE_TEACHER = 'teacher';
export const USER_TYPE_STUDENT = 'student';
