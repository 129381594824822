import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  Tabs,
  Text,
  Stack,
  Title,
  Button,
  TabPanel,
} from '@bubbles-ui/components';
import { ChatListTabsStyles } from './ChatListTabs.styles';
import { ChatListTab } from './ChatListTab';

function ChatListTabs({
  t,
  activeTab,
  roomChats,
  roomFavs,
  roomSubjects,
  roomActivities,
  canAddGroup,
  onTabClick = noop,
  onRoomClick = noop,
  onNewChat = noop,
  onNewGroup = noop,
}) {
  const scrollRef = React.useRef(null);
  const { classes } = ChatListTabsStyles({}, { name: 'ChatListTabs' });
  const allChatRooms = [
    ...(roomChats ?? []),
    ...(roomSubjects ?? []),
    ...(roomActivities ?? []),
  ].sort(
    (a, b) =>
      new Date(b.lastMessage?.createdAt || 0) -
      new Date(a.lastMessage?.createdAt || 0)
  );

  return (
    <Tabs
      centerGrow={true}
      className={classes.tab}
      activeKey={activeTab}
      onTabClick={(e) => {
        onTabClick(e);
      }}
    >
      {/* --- Chats --- */}
      <TabPanel
        /* notification={store.roomChatsUnreadMessages || null} */
        key={'chats'}
        label={t('tabs.chats')}
      >
        <ChatListTab
          t={t}
          classes={classes}
          scrollRef={scrollRef}
          rooms={allChatRooms}
          onRoomClick={onRoomClick}
        >
          <Title order={2}>{t('empty.chatsTitle')}</Title>

          <Stack spacing={2} direction="column">
            <Button variant="outline" onClick={onNewChat}>
              {t('empty.chatsButtonNewChat')}
            </Button>
            {canAddGroup && (
              <Button variant="outline" onClick={onNewGroup}>
                {t('empty.chatsButtonNewGroup')}
              </Button>
            )}
          </Stack>
          <Text>{/* t('empty.chatsText')} */}</Text>
        </ChatListTab>
      </TabPanel>
      {/* --- Favs --- */}
      <TabPanel
        /* notification={store.roomLikesUnreadMessages || null} */
        key={'likes'}
        label={t('tabs.likes')}
      >
        <ChatListTab
          t={t}
          classes={classes}
          scrollRef={scrollRef}
          rooms={roomFavs}
          onRoomClick={onRoomClick}
        >
          <Title order={2}>{t('empty.likesTitle')}</Title>
          <Text size="md">{t('empty.likesText')}</Text>
        </ChatListTab>
      </TabPanel>
    </Tabs>
  );
}

ChatListTabs.propTypes = {
  t: PropTypes.func,
  activeTab: PropTypes.string,
  roomChats: PropTypes.array,
  roomFavs: PropTypes.array,
  roomSubjects: PropTypes.array,
  roomActivities: PropTypes.array,
  canAddGroup: PropTypes.bool,
  onTabClick: PropTypes.func,
  onRoomClick: PropTypes.func,
  onNewChat: PropTypes.func,
  onNewGroup: PropTypes.func,
};

export { ChatListTabs };
