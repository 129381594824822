import React from 'react';
import PropTypes from 'prop-types';
import { Box, Textarea, IconButton, Popover } from '@bubbles-ui/components';
import { SendMessageIcon } from '@bubbles-ui/icons/outline';
import { EmogiIcon, GifIcon } from '@bubbles-ui/icons/solid';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import GifPicker from 'gif-picker-react';
import { ChatDrawerFooterStyles } from './ChatDrawerFooter.styles';

function ChatDrawerFooter({
  t,
  tenorApiKey,
  message,
  onMessageChange,
  onSendMessage,
  onSendImageMessage,
  onInsertEmoji,
}) {
  const { classes } = ChatDrawerFooterStyles({}, { name: 'ChatDrawerFooter' });
  return (
    <Box className={classes.sendMessage}>
      <Textarea
        value={message}
        name="message"
        autosize={false}
        textareaStyles={{ height: 40, paddingTop: 8 }}
        placeholder={t('writeNewMessage')}
        className={classes.textarea}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            onSendMessage();
          }
        }}
        onChange={onMessageChange}
        rightSection={
          <Box
            sx={{
              display: 'flex',
              width: tenorApiKey ? 60 : 30,
              position: 'absolute',
              right: 0,
              gap: 8,
            }}
          >
            {!!tenorApiKey && (
              <Popover
                withinPortal={false}
                position="top-start"
                target={
                  <Box
                    sx={{
                      cursor: 'pointer',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <GifIcon />
                  </Box>
                }
              >
                <GifPicker
                  onGifClick={(tenor) => onSendImageMessage(tenor.url)}
                  tenorApiKey={tenorApiKey}
                />
              </Popover>
            )}

            <Popover
              withinPortal={true}
              position="top"
              target={
                <Box
                  sx={{
                    cursor: 'pointer',
                    fontSize: 18,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <EmogiIcon />
                </Box>
              }
            >
              <Picker data={data} onEmojiSelect={onInsertEmoji} />
            </Popover>
          </Box>
        }
      />
      <IconButton
        onClick={onSendMessage}
        icon={<SendMessageIcon />}
        color="primary"
      />
    </Box>
  );
}

ChatDrawerFooter.propTypes = {
  t: PropTypes.func,
  tenorApiKey: PropTypes.string,
  message: PropTypes.string,
  onMessageChange: PropTypes.func,
  onSendMessage: PropTypes.func,
  onSendImageMessage: PropTypes.func,
  onInsertEmoji: PropTypes.func,
};

export { ChatDrawerFooter };
