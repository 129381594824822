import { CHAT_ROOM_1ON1_TYPE, USER_TYPE_STUDENT } from '@comunica/constants';

export function isStudentsChatRoom(room) {
  if (room?.type === CHAT_ROOM_1ON1_TYPE) {
    return (
      room.userAgents[0].userAgent.profile.sysName === USER_TYPE_STUDENT &&
      room.userAgents[1].userAgent.profile.sysName === USER_TYPE_STUDENT
    );
  }
  return false;
}

export default isStudentsChatRoom;
