import { createStyles } from '@bubbles-ui/components';

const ChatListResultsStyles = createStyles((theme) => {
  const { content } = theme.other.global;

  return {
    searchTitle: {
      ...content.typo.body['md--bold'],
      paddingInline: theme.spacing[5],
      color: theme.other.global.content.color.text.emphasis,
    },
    listItems: {
      paddingTop: theme.spacing[4],
      backgroundColor: '#F8F9FB',
    },
    emptyImage: {
      marginBlock: theme.spacing[6],
    },
  };
});

export { ChatListResultsStyles };
