import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Badge,
  Stack,
  Title,
  ChatMessage,
  ImageLoader,
} from '@bubbles-ui/components';
import {
  CHAT_ROOM_1ON1_TYPE,
  USER_TYPE_ADMIN,
  USER_TYPE_TEACHER,
} from '@comunica/constants';
import { useLocale } from '@common';
import { ChatDrawerMessageListStyles } from './ChatDrawerMessageList.styles';

function ChatDrawerMessageList({
  t,
  room,
  messages,
  userAgent,
  userAgentsById,
  selectedMessage,
  canShowSelected,
}) {
  const { classes } = ChatDrawerMessageListStyles(
    {},
    { name: 'ChatDrawerMessageList' }
  );
  const locale = useLocale();
  const lastDay = React.useRef(null);

  // Empty State
  if (!messages?.length) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        fullWidth
        fullHeight
        spacing={4}
        sx={{ textAlign: 'center', paddingInline: 50, paddingTop: 50 }}
      >
        <Box>
          <ImageLoader
            className={classes.emptyImage}
            src={'/public/comunica/empty-subjects.svg'}
            forceImage
            width={250}
            height={194}
          />
          <Title order={2}>{t('empty.subjectTitle')}</Title>
          <Text size="md">{t('empty.subjectText')}</Text>
        </Box>
      </Stack>
    );
  }

  return (
    <Box>
      {messages?.map((message, index) => {
        const toRender = [];
        let forceUserImage = false;
        const day = new Date(message.createdAt).toLocaleDateString(locale, {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });

        if (index === 0 || lastDay.current !== day) {
          lastDay.current = day;
          forceUserImage = true;
          toRender.push(
            <Box className={classes.date} key={`date-${index}`}>
              <Badge
                label={day}
                disableHover
                radius="default"
                closable={false}
                size="xs"
              />
            </Box>
          );
        }
        toRender.push(
          <Box
            id={message.id}
            key={message.id}
            sx={(theme) => ({
              marginTop:
                index !== 0 &&
                messages[index - 1].userAgent !== message.userAgent
                  ? theme.spacing[4]
                  : 0,
            })}
          >
            <ChatMessage
              showUser={
                forceUserImage || index === 0
                  ? true
                  : messages[index - 1].userAgent !== message.userAgent
              }
              previoudMessageIsSameUser={
                index === 0
                  ? false
                  : messages[index - 1].userAgent === message.userAgent
              }
              showUserName={room?.type !== CHAT_ROOM_1ON1_TYPE}
              isTeacher={
                userAgentsById?.[message.userAgent]?.profile?.sysName ===
                USER_TYPE_TEACHER
              }
              isAdmin={
                userAgentsById?.[message.userAgent]?.profile?.sysName ===
                USER_TYPE_ADMIN
              }
              highlight={selectedMessage?.query}
              selected={selectedMessage?.id === message.id && canShowSelected}
              isOwn={message.userAgent === userAgent}
              user={userAgentsById?.[message.userAgent]?.user}
              message={{
                ...message.message,
                date: message.createdAt,
              }}
            />
          </Box>
        );
        return toRender;
      })}
    </Box>
  );
}

ChatDrawerMessageList.propTypes = {
  t: PropTypes.func,
  room: PropTypes.object,
  locale: PropTypes.string,
  messages: PropTypes.array,
  userAgent: PropTypes.string,
  userAgentsById: PropTypes.object,
  selectedMessage: PropTypes.object,
  canShowSelected: PropTypes.bool,
};

export { ChatDrawerMessageList };
