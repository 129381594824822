import React from 'react';
import { ASSIGN_ICON_PROP_TYPES, ASSIGN_ICON_DEFAULT_PROPS } from './AssignIcon.constants';

const AssignIcon = ({ width, height, color }) => (
  <svg
    viewBox="0 0 18 18"
    stroke="currentColor"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.23 0C13.1878 0 14.1063 0.38048 14.7836 1.05774C15.4609 1.735 15.8413 2.65356 15.8413 3.61134C15.8413 4.56913 15.4609 5.48769 14.7836 6.16495C14.1063 6.84221 13.1878 7.22269 12.23 7.22269C11.2722 7.22269 10.3536 6.84221 9.67638 6.16495C8.99912 5.48769 8.61865 4.56913 8.61865 3.61134C8.61865 2.65356 8.99912 1.735 9.67638 1.05774C10.3536 0.38048 11.2722 0 12.23 0ZM14.0884 1.75296C13.5955 1.26009 12.927 0.983193 12.23 0.983193C11.533 0.983193 10.8645 1.26009 10.3716 1.75296C9.87873 2.24583 9.60184 2.91432 9.60184 3.61134C9.60184 4.30837 9.87873 4.97685 10.3716 5.46973C10.8645 5.9626 11.533 6.2395 12.23 6.2395C12.927 6.2395 13.5955 5.9626 14.0884 5.46973C14.5812 4.97685 14.8581 4.30837 14.8581 3.61134C14.8581 2.91432 14.5812 2.24583 14.0884 1.75296ZM10.4185 8.72772C11.1202 8.42875 11.885 8.30833 12.6446 8.37722C13.4042 8.44611 14.1349 8.70217 14.7714 9.1225C15.4079 9.54283 15.9302 10.1143 16.2918 10.7859C16.6534 11.4575 16.8429 12.2082 16.8434 12.9709C16.8436 13.2424 17.0639 13.4624 17.3354 13.4622C17.6069 13.462 17.8268 13.2418 17.8266 12.9703C17.826 12.0451 17.5961 11.1344 17.1575 10.3198C16.7189 9.50518 16.0852 8.81195 15.3132 8.30208C14.5412 7.79221 13.6548 7.48161 12.7334 7.39805C11.812 7.31448 10.8843 7.46055 10.0331 7.8232C9.78332 7.92963 9.66711 8.21838 9.77353 8.46816C9.87995 8.71793 10.1687 8.83414 10.4185 8.72772Z"
      fill={color}
      strokeWidth="0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.00186855 14.1552C0.0312949 14.346 0.192798 14.5196 0.377139 14.5587C0.418907 14.5675 0.982185 14.569 4.40002 14.5691L8.37434 14.5692L7.08139 15.8634C6.37027 16.5752 5.77659 17.1742 5.76211 17.1945C5.61953 17.3944 5.64476 17.6642 5.8222 17.8369C5.98431 17.9947 6.24045 18.0198 6.42691 17.8963C6.46981 17.8679 6.84 17.5011 8.22755 16.1123C9.86032 14.478 9.97588 14.3607 10.0001 14.3135C10.0386 14.2382 10.056 14.1679 10.0565 14.0854C10.057 13.9927 10.0467 13.9456 10.0077 13.8636L9.97519 13.7952L8.21006 12.0306L6.44491 10.2659L6.38371 10.2358C6.30211 10.1956 6.23533 10.1821 6.13891 10.1865C6.00975 10.1923 5.90768 10.2361 5.81925 10.3237C5.72394 10.4181 5.67882 10.5195 5.67293 10.6524C5.66866 10.7489 5.68214 10.8157 5.72228 10.8972L5.75244 10.9584L7.06341 12.2706L8.37438 13.5828L4.44325 13.583C2.17228 13.5831 0.484744 13.5859 0.447311 13.5897C0.366764 13.5977 0.302444 13.6176 0.243224 13.6527C0.140041 13.714 0.0624409 13.8087 0.0239785 13.9203C0.00657614 13.9708 0.00223475 13.9963 0.000571552 14.0579C-0.000551648 14.0996 3.25489e-05 14.1433 0.00186855 14.1552Z"
      fill={color}
      strokeWidth="0"
    />
  </svg>
);

AssignIcon.defaultProps = ASSIGN_ICON_DEFAULT_PROPS;
AssignIcon.propTypes = ASSIGN_ICON_PROP_TYPES;

AssignIcon.displayName = 'AssignIcon';
export default AssignIcon;
export { AssignIcon };
