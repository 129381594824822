import { createStyles } from '@bubbles-ui/components';

// eslint-disable-next-line import/prefer-default-export

export const ChatAddUsersDrawerStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 9999,
    display: 'flex',
    height: '100%',
    justifyContent: 'start',
    borderLeft: `1px solid ${theme.colors.ui01}`,
    width: 400,
    flexDirection: 'column',
  },
  header: {
    padding: `${theme.spacing[2]}px ${theme.spacing[4]}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.mainWhite,
    zIndex: 2,
    height: 80,
    button: {
      color: theme.other.buttonAction.content.color.primary.default,
    },
  },
  content: {
    padding: theme.spacing[5],
    paddingTop: theme.spacing[3],
    flex: 1,
    overflowY: 'auto',
    backgroundColor: '#F8F9FB',
  },
  title: {
    fontFamily: 'Albert Sans',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.other.global.content.color.text.default,
    marginBottom: theme.spacing[3],
  },
  participants: {
    marginBottom: theme.spacing[2],
    fontFamily: 'Albert Sans',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.other.global.content.color.text.default,
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userInfoIcons: {
    display: 'flex',
    gap: theme.spacing[2],
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: theme.other.divider.background.color.default,
  },
  userAgentItem: {
    cursor: 'pointer',
    marginLeft: -theme.spacing[5],
    marginRight: -theme.spacing[5],
    paddingLeft: theme.spacing[5],
    paddingRight: theme.spacing[5],
    display: 'flex',
    alignItems: 'center',
    '.mantine-UserDisplayItem-root': {
      flex: 1,
    },
    '&:hover': {
      backgroundColor: theme.other.global.background.color.surface.subtle,
    },
  },
  userAgents: {
    marginTop: theme.spacing[3],
  },
  results: {
    fontFamily: 'Albert Sans',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.other.global.content.color.text.emphasis,
    paddingTop: theme.spacing[4],
    paddingBottom: theme.spacing[4],
  },
  buttonActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: theme.spacing[2],
    padding: `${theme.spacing[3]}px ${theme.spacing[6]}px`,
    borderTop: `1px solid ${theme.other.global.border.color.line.muted}`,
  },
  searchTitle: {
    fontFamily: 'Albert Sans',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.other.global.content.color.text.emphasis,
    marginBottom: theme.spacing[4],
  },
}));
