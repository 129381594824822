import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Stack,
  Title,
  Loader,
  ImageLoader,
  UserDisplayItem,
} from '@bubbles-ui/components';
import ChatListDrawerItem from '@comunica/components/ChatListDrawerItem/ChatListDrawerItem';
import { ChatMessageSearch } from '../../../ChatMessageSearch/ChatMessageSearch';
import { ChatListResultsStyles } from './ChatListResults.styles';

function ChatListResults({
  t,
  searching,
  searchResults,
  highlight,
  onClickUserAgent,
  onClickRoom,
  onClickMessage,
}) {
  const { classes } = ChatListResultsStyles({}, { name: 'ChatListResults' });
  return (
    <Box className={classes.listItems}>
      {searching ? (
        <Stack fullWidth sx={{ marginTop: 50 }}>
          <Loader />
        </Stack>
      ) : (
        <>
          <Stack direction="column" fullWidth spacing={4}>
            {/* --- User agents --- */}
            {searchResults?.userAgents?.length > 0 && (
              <Box>
                <Box className={classes.searchTitle}>
                  {t('searchs.userAgents')}
                </Box>
                {searchResults?.userAgents?.map((userAgent) => (
                  <Box
                    sx={(theme) => ({
                      paddingInline: 18,
                      paddingBlock: 8,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor:
                          theme.other.global.background.color.surface.muted,
                      },
                    })}
                    onClick={() => onClickUserAgent(userAgent)}
                    key={userAgent.id}
                  >
                    <UserDisplayItem
                      {...userAgent.user}
                      noBreak={false}
                      highlight={highlight}
                      rol={userAgent?.profile?.name}
                      variant="rol"
                      size="xmd"
                    />
                  </Box>
                ))}
              </Box>
            )}

            {/* --- Room --- */}
            {searchResults?.rooms?.length > 0 && (
              <Box>
                <Box className={classes.searchTitle}>{t('searchs.chats')}</Box>
                {searchResults?.rooms?.map((room) => (
                  <ChatListDrawerItem
                    t={t}
                    key={room.id}
                    room={room}
                    highlight={highlight}
                    onClick={() => onClickRoom(room)}
                  />
                ))}
              </Box>
            )}

            {/* --- Mensajes --- */}
            {searchResults?.messages?.length > 0 && (
              <Box>
                <Box className={classes.searchTitle}>
                  {t('searchs.messages')}
                </Box>
                {searchResults?.messages?.map((message) => (
                  <Box
                    key={message.id}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClickMessage(message)}
                  >
                    <ChatMessageSearch
                      t={t}
                      message={message}
                      highlight={highlight}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </Stack>

          {searchResults?.rooms?.length <= 0 &&
            searchResults?.messages?.length <= 0 && (
              <Stack
                direction="column"
                alignItems="center"
                fullWidth
                fullHeight
                spacing={4}
                sx={{ textAlign: 'center', paddingInline: 50, paddingTop: 50 }}
              >
                <ImageLoader
                  className={classes.emptyImage}
                  src={'/public/comunica/no-results.svg'}
                  forceImage
                  width={238}
                  height={250}
                />
                <Title order={2}>{t('noResultsTitle')}</Title>
                <Text size="md">{t('noResultsText')}</Text>
              </Stack>
            )}
        </>
      )}
    </Box>
  );
}

ChatListResults.propTypes = {
  t: PropTypes.func,
  searching: PropTypes.bool,
  searchResults: PropTypes.object,
  highlight: PropTypes.string,
  onClickUserAgent: PropTypes.func,
  onClickRoom: PropTypes.func,
  onClickMessage: PropTypes.func,
};

export default ChatListResults;
