import {
  Box,
  Button,
  TextClamp,
  Text,
  stringToHslColor,
} from '@bubbles-ui/components';
import { VolumeControlOffIcon } from '@bubbles-ui/icons/solid';
import { RoomAvatar } from '@comunica/components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  CHAT_ROOM_1ON1_PARENT_TYPE,
  CHAT_ROOM_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_SUBJECT_TYPE,
  CHAT_ROOM_GROUP_TYPE,
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  CHAT_ROOM_SUBJECT_TEACHERS_TYPE,
  GROUPED_ROOMS_ACTIVITY_TYPE,
  GROUPED_ROOMS_SUBJECT_TYPE,
  USER_TYPE_TEACHER,
} from '@comunica/constants';
import { useIsTeacher } from '@academic-portfolio/hooks';
import useAssignationsByProfile from '@assignables/hooks/assignations/useAssignationsByProfile';
import { getSessionUserAgent } from '@users/session';
import { RoomHeaderStyles } from './RoomHeader.styles';

const noNUsersTypes = [
  GROUPED_ROOMS_ACTIVITY_TYPE,
  CHAT_ROOM_ACTIVITY_SUBJECT_TYPE,
  CHAT_ROOM_1ON1_TYPE,
];

function RoomHeader({
  t,
  room: roomProp,
  onImageChange,
  onShowInfo,
  subjectRooms,
}) {
  const history = useHistory();
  const isTeacher = useIsTeacher();
  const userAgentId = getSessionUserAgent();
  const room = roomProp;

  const isActivity = room?.type.includes('assignable');
  const { data: assignations } = useAssignationsByProfile(
    [room?.metadata?.assignableInstanceId],
    { enabled: isActivity }
  );

  const { classes } = RoomHeaderStyles(
    { type: room.type },
    { name: 'RoomHeader' }
  );

  const nUsers = _.filter(room.userAgents, (e) => !e.deleted).length;

  function goSubject(key) {
    const classId = key.replace('academic-portfolio.room.class.group.', '');
    history.push(
      `/private/dashboard/class/${classId}?type=all&sort=assignation&query=&progress=all`
    );
  }

  function goClass(key) {
    const classId = key.replace('academic-portfolio.room.class.', '');
    history.push(
      `/private/dashboard/class/${classId}?type=all&sort=assignation&query=&progress=all`
    );
  }

  function goActivity(assignation) {
    const instance = assignation?.instance ?? assignation ?? null;
    const finished = !!assignation?.finished;
    const roleDetails = instance?.assignable?.roleDetails;

    let url = '';

    if (isTeacher) {
      url = `/private/assignables/details/${instance.id}`;
    } else if (finished) {
      url = roleDetails.evaluationDetailUrl.replace(':id', instance.id);
    } else {
      url = roleDetails.studentDetailUrl.replace(':id', instance.id);
    }

    url = url.replace(':user', userAgentId);
    history.push(url);
  }

  const isSubjectRoom =
    isTeacher && room.type === CHAT_ROOM_SUBJECT_TEACHERS_TYPE;

  const isActivityRoom =
    isTeacher && room.type === CHAT_ROOM_ACTIVITY_1ON1_TYPE;

  const is1on1Room =
    isTeacher &&
    (room.type === CHAT_ROOM_1ON1_TYPE ||
      room.type === CHAT_ROOM_1ON1_PARENT_TYPE);

  const otherUser = _.filter(
    room.userAgents,
    (e) => e.userAgent.profile.sysName !== USER_TYPE_TEACHER
  );

  if (isActivityRoom || isSubjectRoom || is1on1Room) {
    room.imageIsUser = true;
    room.imageIsUrl = false;
    room.metadata.headerImageIsUser = true;
    room.nameReplaces = `${otherUser[0].userAgent.user.name} ${otherUser[0].userAgent.user.surnames}`;
    room.name = `${otherUser[0].userAgent.user.name} ${otherUser[0].userAgent.user.surnames}`;
    room.image = otherUser[0].userAgent.user.avatar ?? null;
    room.metadata.headerImage = room.image;
    room.icon = null;
    room.bgColor = room.image ? null : stringToHslColor(room.name, 50, 50);
    room.metadata.headerBgColor = '#FFFFFF';
    room.metadata.headerIcon = null;
  }

  const subjectName = subjectRooms?.find((r) => typeof r !== 'undefined')?.name;

  return (
    <Box className={classes.container}>
      <Box className={classes.leftSide}>
        <RoomAvatar
          onImageChange={onImageChange}
          isHeader={true}
          room={room}
          size={44}
        />
        <Box className={classes.textsContainer}>
          {room.metadata?.headerName || room.name ? (
            <Box className={classes.title}>
              <TextClamp lines={1}>
                <Text strong>
                  {isSubjectRoom && otherUser.length === 1
                    ? `${otherUser[0].userAgent.user.name} ${otherUser[0].userAgent.user.surnames}`
                    : t(
                        room.metadata?.headerName || room.name,
                        room.nameReplaces,
                        false,
                        room.metadata?.headerName || room.name
                      )}
                </Text>
              </TextClamp>
            </Box>
          ) : null}

          {room.metadata?.headerSubName || room.subName || subjectName ? (
            <Box className={classes.subNameContainer}>
              {/* subNameIcon */}
              <Box className={classes.subName}>
                <TextClamp lines={1}>
                  <Text>
                    {isSubjectRoom && typeof subjectName !== 'undefined'
                      ? subjectName
                      : t(
                          room.metadata?.headerSubName || room.subName,
                          room.metadata?.headerSubNameReplaces || {},
                          false,
                          room.metadata?.headerSubName || room.subName
                        )}
                    {room.type !== CHAT_ROOM_GROUP_TYPE ? ` (${nUsers})` : null}
                  </Text>
                </TextClamp>
              </Box>
              {room.type === CHAT_ROOM_GROUP_TYPE && (
                <Box className={classes.nsubName}>
                  <TextClamp lines={1}>
                    <Text>
                      {!noNUsersTypes.includes(room.type)
                        ? `(${nUsers})`
                        : null}
                    </Text>
                  </TextClamp>
                </Box>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box className={classes.rightSide}>
        {room?.type === GROUPED_ROOMS_SUBJECT_TYPE && (
          <Button variant="link" onClick={() => goSubject(room.key)}>
            {t('showSubject')}
          </Button>
        )}
        {room?.type === CHAT_ROOM_SUBJECT_ALL_TYPE && (
          <Button variant="link" onClick={() => goClass(room.key)}>
            {t('showClass')}
          </Button>
        )}
        {room?.type === CHAT_ROOM_GROUP_TYPE && (
          <Button variant="link" onClick={onShowInfo}>
            {t('showInfo')}
          </Button>
        )}
        {room?.type.includes('assignable') && (
          <Button variant="link" onClick={() => goActivity(assignations[0])}>
            {t('showActivity')}
          </Button>
        )}
        {room.muted ? (
          <Box className={classes.muteIcon}>
            <VolumeControlOffIcon />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

RoomHeader.propTypes = {
  t: PropTypes.func,
  room: PropTypes.any,
  onImageChange: PropTypes.func,
  onShowInfo: PropTypes.func,
  subjectRooms: PropTypes.array,
};

export { RoomHeader };
export default RoomHeader;
