import {
  CHAT_ROOM_1ON1_TYPE,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from '@comunica/constants';

export function isStudentTeacherChatRoom(room) {
  if (room?.type === CHAT_ROOM_1ON1_TYPE) {
    return (
      (room.userAgents[0].userAgent.profile.sysName === USER_TYPE_STUDENT &&
        room.userAgents[1].userAgent.profile.sysName === USER_TYPE_TEACHER) ||
      (room.userAgents[1].userAgent.profile.sysName === USER_TYPE_STUDENT &&
        room.userAgents[0].userAgent.profile.sysName === USER_TYPE_TEACHER)
    );
  }
  return false;
}

export default isStudentTeacherChatRoom;
