import { createStyles } from '@bubbles-ui/components'; // eslint-disable-next-line import/prefer-default-export

const ChatDrawerFooterStyles = createStyles((theme) => ({
  sendMessage: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing[4],
    gap: theme.spacing[2],
    backgroundColor: '#FFFFFF',
  },
  textarea: {
    flex: 1,
    height: 40,
  },
}));

export { ChatDrawerFooterStyles };
