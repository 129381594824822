import PropTypes from 'prop-types';

export const ASSET_PLAYER_DEFAULT_PROPS = {
  asset: {},
  height: 'auto',
  width: '100%',
  styles: {},
  playing: false,
  muted: false,
  volume: 1,
  loop: false,
  fullScreen: false,
  nativeControls: false,
  progressInterval: 100,
  controlBar: true,
  framed: false,
  canPlay: true,
  hideURLInfo: false,
  useAudioCard: false,
  pdfLabels: {
    pageLabel: '',
    paginatorLabel: '',
    schemaLabel: '',
  },
  useSchema: true,
  viewPDF: true,
  compact: false,
  useAspectRatio: true,
  showPlayButton: true,
  ccMode: false,
};
export const ASSET_PLAYER_PROP_TYPES = {
  asset: PropTypes.any,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.object,
  className: PropTypes.string,
  playing: PropTypes.bool,
  muted: PropTypes.bool,
  volume: PropTypes.number,
  loop: PropTypes.bool,
  fullScreen: PropTypes.bool,
  nativeControls: PropTypes.bool,
  progressInterval: PropTypes.number,
  controlBar: PropTypes.bool,
  framed: PropTypes.bool,
  canPlay: PropTypes.bool,
  hideURLInfo: PropTypes.bool,
  useAudioCard: PropTypes.bool,
  pdfLabels: PropTypes.shape({
    pageLabel: PropTypes.string,
    paginatorLabel: PropTypes.string,
    schemaLabel: PropTypes.string,
  }),
  useSchema: PropTypes.bool,
  viewPDF: PropTypes.bool,
  compact: PropTypes.bool,
  useAspectRatio: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  ccMode: PropTypes.bool,
};
