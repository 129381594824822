import { createStyles } from '@bubbles-ui/components';

const ChatListHeaderStyles = createStyles((theme, { filter }) => ({
  header: {
    height: 72,
    paddingInline: theme.spacing[4],
    backgroundColor: theme.colors.mainWhite,
    zIndex: 2,
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing[2],
    paddingInline: theme.spacing[4],
    paddingBottom: filter ? theme.spacing[4] : theme.spacing[2],
    backgroundColor: theme.colors.mainWhite,
  },
}));

export { ChatListHeaderStyles };
