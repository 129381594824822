import { createStyles } from '@bubbles-ui/components'; // eslint-disable-next-line import/prefer-default-export

const ChatDrawerMessageListStyles = createStyles((theme) => ({
  date: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginTop: theme.spacing[4],
    marginBottom: theme.spacing[4],
  },
  emptyImage: {
    marginTop: theme.spacing[6],
    marginBottom: theme.spacing[6],
  },
}));

export { ChatDrawerMessageListStyles };
