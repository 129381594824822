import { createStyles } from '@bubbles-ui/components'; // eslint-disable-next-line import/prefer-default-export

// eslint-disable-next-line import/prefer-default-export
export const ChatInfoDrawerStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 9999,
    display: 'flex',
    height: '100%',
    justifyContent: 'start',
    borderLeft: `1px solid ${theme.colors.ui01}`,
    width: 400,
    flexDirection: 'column',
  },
  header: {
    padding: `${theme.spacing[2]}px ${theme.spacing[4]}px`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.mainWhite,
    zIndex: 2,
    height: 80,
    button: {
      color: theme.other.buttonAction.content.color.primary.default,
    },
  },
  content: {
    padding: theme.spacing[5],
    flex: 1,
    overflowY: 'auto',
    backgroundColor: '#F8F9FB',
  },
  participants: {
    marginTop: theme.spacing[5],
    marginBottom: theme.spacing[2],
    ...theme.other.global.content.typo.body['lg--bold'],
    color: theme.other.global.content.color.text.default,
  },
  showAll: {
    ...theme.other.button.content.typo,
    color: theme.other.link.content.color.default,
    marginTop: theme.spacing[3],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: theme.spacing[1],
    cursor: 'pointer',
  },
  userInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userInfoIcons: {
    display: 'flex',
    gap: theme.spacing[2],
  },
  userAdmin: {
    ...theme.other.buttonText.content.typo,
    color: theme.other.global.content.color.text.default,
  },
  userRemove: {
    color: theme.other.global.content.color.icon.default,
  },
  userMuteIcon: {
    button: {
      color: theme.other.buttonIcon.content.color.terciary.default,
    },
  },
  userMuteIconActive: {
    button: {
      color: theme.other.buttonIcon.content.color.terciary.hover,
    },
  },
  adminIcons: {
    display: 'flex',
  },
  name: {
    marginBottom: theme.spacing[5],
  },
  buttonActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: theme.spacing[2],
    padding: `${theme.spacing[3]}px ${theme.spacing[6]}px`,
    borderTop: `1px solid ${theme.other.global.border.color.line.muted}`,
  },
  addTitle: {
    fontFamily: 'Albert Sans',
    fontWeight: 500,
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: '24px',
    color: theme.other.global.content.color.text.emphasis,
    marginBottom: theme.spacing[3],
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    width: 120,
    height: 120,
    margin: '0 auto',
    backgroundColor: '#878D96',
    borderRadius: '50%',
    marginBottom: theme.spacing[5],
    '&:hover': {
      '&:after': {
        opacity: 1,
      },
      '> div': {
        opacity: '1!important',
      },
    },
    '&:after': {
      display: 'block',
      opacity: 0,
      content: '""',
      width: 120,
      height: 120,
      backgroundColor: '#343A3F80',
      position: 'absolute',
      borderRadius: '50%',
      left: 'calc(50% - 60px)',
      top: 'calc(50% - 60px)',
      pointerEvents: 'none',
    },
  },
  imageEdit: {
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#ffffff',
    zIndex: 2,
    fontSize: 32,
    pointerEvents: 'none',
  },
  newGroupTitle: {
    marginBottom: theme.spacing[5],
    ...theme.other.global.content.typo.body['lg--bold'],
  },
  groupTitle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing[3],
  },
  imageWrapper: {
    marginBottom: theme.spacing[5],
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));
