/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import _, { isString, noop } from 'lodash';
import { useIsStudent, useIsTeacher } from '@academic-portfolio/hooks';
import { Box, BaseDrawer, useDebouncedCallback } from '@bubbles-ui/components';
import { useStore } from '@common';
import ChatAddUsersDrawer from '@comunica/components/ChatAddUsersDrawer/ChatAddUsersDrawer';
import ChatInfoDrawer from '@comunica/components/ChatInfoDrawer/ChatInfoDrawer';
import getChatUserAgent from '@comunica/helpers/getChatUserAgent';
import getRoomChildrens from '@comunica/helpers/getRoomChildrens';
import getTotalUnreadMessages from '@comunica/helpers/getTotalUnreadMessages';
import isStudentsChatRoom from '@comunica/helpers/isStudentsChatRoom';
import isStudentTeacherChatRoom from '@comunica/helpers/isStudentTeacherChatRoom';
import prefixPN from '@comunica/helpers/prefixPN';
import SocketIoService from '@mqtt-socket-io/service';
import useTranslateLoader from '@multilanguage/useTranslateLoader';
import { getSessionCenter, getSessionUserAgent } from '@users/session';
import {
  CHAT_ROOM_1ON1_PARENT_TYPE,
  CHAT_ROOM_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_ALL_TYPE,
  CHAT_ROOM_ACTIVITY_SUBJECT_TYPE,
  CHAT_ROOM_GROUP_TYPE,
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  EVENT_CONFIG_CENTER,
  EVENT_CONFIG_PROGRAM,
  EVENT_CONFIG_ROOM,
  EVENT_ROOM_ADDED,
  EVENT_ROOM_REMOVE,
  EVENT_ROOM_UPDATE_IMAGE,
  EVENT_ROOM_UPDATE_NAME,
  EVENT_ROOM_USERS_REMOVED,
  EVENT_ROOM_USER_ADDED,
  GROUPED_ROOMS_SUBJECT_TYPE,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from '@comunica/constants';
import { addErrorAlert } from '@layout/alert';
import useRequestErrorMessage from '@common/useRequestErrorMessage';
import { RoomService } from '../../RoomService';
import { ChatDrawer } from '../ChatDrawer/ChatDrawer';
import { ChatListDrawerStyles } from './ChatListDrawer.styles';
import { ChatListHeader } from './components/ChatListHeader/ChatListHeader';
import { ChatListTabs } from './components/ChatListTabs/ChatListTabs';
import ChatListResults from './components/ChatListResults/ChatListResults';

function ChatListDrawer({
  opened: openedProp,
  openRoom,
  onRoomOpened = noop,
  onClose = noop,
}) {
  const debouncedFunction = useDebouncedCallback(100);
  const debouncedFunction2 = useDebouncedCallback(100);
  const debouncedSearch = useDebouncedCallback(1000);
  const [, , , getErrorMessage] = useRequestErrorMessage();
  const { classes } = ChatListDrawerStyles({}, { name: 'ChatListDrawer' });
  const [t] = useTranslateLoader(prefixPN('chatListDrawer'));
  const [store, render] = useStore({
    rooms: [],
    subjectRooms: [],
    opened: openedProp,
  });
  const isStudent = useIsStudent();
  const isTeacher = useIsTeacher();
  const userAgentId = getSessionUserAgent();
  const center = getSessionCenter();

  store.roomNewChat = React.useMemo(() => {
    const agents = [];
    _.forEach(store.chatRooms, (room) => {
      if (room.type === CHAT_ROOM_1ON1_TYPE) {
        agents.push(getChatUserAgent(room.userAgents));
      }
    });
    return {
      program: store.selectedProgramID,
      userAgents: [
        ...agents,
        {
          deleted: false,
          userAgent: {
            id: userAgentId,
          },
        },
      ],
    };
  }, [store.originalRooms]);

  // ·····································
  // METHODS

  function filterSearch() {
    // Filtramos las busquedas
    if (store.typeFilters?.length) {
      store.searchResultsRooms.rooms = _.filter(
        store.searchResultsRooms._rooms,
        (room) => store.typeFilters.includes(room.type)
      );
      if (store.typeFilters.includes('userAgents')) {
        store.searchResultsRooms.userAgents =
          store.searchResultsRooms._userAgents;
      } else {
        store.searchResultsRooms.userAgents = [];
      }
      if (store.typeFilters.includes('messages')) {
        store.searchResultsRooms.messages = store.searchResultsRooms._messages;
      } else {
        store.searchResultsRooms.messages = [];
      }
    } else {
      store.searchResultsRooms.rooms = store.searchResultsRooms._rooms;
      store.searchResultsRooms.messages = store.searchResultsRooms._messages;
      store.searchResultsRooms.userAgents =
        store.searchResultsRooms._userAgents;
    }
  }

  function filterRoomsByProgram(rooms) {
    return _.filter(
      rooms,
      (room) =>
        !store.selectedProgramID ||
        !room.program ||
        room.program === store.selectedProgramID
    );
  }

  function applyCenterConfigFilters(rooms) {
    if (!store.centerConfig?.enableStudentsChats) {
      rooms = _.filter(rooms, (room) => !isStudentsChatRoom(room));
    }
    if (store.centerConfig?.disableChatsBetweenStudentsAndTeachers) {
      rooms = _.filter(rooms, (room) => !isStudentTeacherChatRoom(room));
    }
    return rooms;
  }

  function applyRoomTypeFilters(rooms) {
    return _.filter(rooms, (room) => {
      if (
        room.type === CHAT_ROOM_SUBJECT_ALL_TYPE &&
        !store.programConfig[room.program]?.enableSubjectsRoom
      ) {
        return false;
      }

      if (
        room.type === CHAT_ROOM_GROUP_TYPE &&
        !store.centerConfig?.enableStudentsCreateGroups
      ) {
        return !_.some(
          room.userAgents,
          (item) =>
            item.isAdmin && item.userAgent.profile.sysName === USER_TYPE_STUDENT
        );
      }

      return true;
    });
  }

  function replaceParentRoomsWithFullObjects(room) {
    return {
      ...room,
      parentRoom: room.parentRoom.map((parentRoomKey) =>
        store.originalRooms.find(
          (originalRoom) => originalRoom.key === parentRoomKey
        )
      ),
    };
  }

  function processSubjectRooms(rooms) {
    const result = _.filter(
      rooms,
      (room) => room.type === CHAT_ROOM_SUBJECT_ALL_TYPE
    );
    return _.map(result, replaceParentRoomsWithFullObjects);
  }

  function processAssignableRooms(rooms) {
    return _.filter(rooms, (room) => {
      const allowedTypes = [
        CHAT_ROOM_ACTIVITY_ALL_TYPE,
        CHAT_ROOM_ACTIVITY_SUBJECT_TYPE,
      ];
      if (isTeacher && room.lastMessage) {
        allowedTypes.push(CHAT_ROOM_ACTIVITY_1ON1_TYPE);
      }
      return allowedTypes.includes(room.type);
    });
  }

  function processChatRooms() {
    // --- New ---
    // Filter all rooms in order to get only ones that belong to the selected program
    let filteredRooms = filterRoomsByProgram(store.originalRooms);
    filteredRooms = applyCenterConfigFilters(filteredRooms);
    filteredRooms = applyRoomTypeFilters(filteredRooms);

    store.favRooms = _.filter(filteredRooms, (room) => !!room.attached);
    store.favRoomsUnreadMessages = _.sum(
      _.map(store.favRooms, 'unreadMessages')
    );

    store.subjectRooms = processSubjectRooms(filteredRooms);
    store.subjectRoomsUnreadMessages = _.sum(
      _.map(store.subjectRooms, 'unreadMessages')
    );

    store.assignableRooms = processAssignableRooms(filteredRooms);
    store.assignableRoomsUnreadMessages = _.sum(
      _.map(store.assignableRooms, 'unreadMessages')
    );

    store.chatRooms = _.filter(filteredRooms, (room) =>
      [CHAT_ROOM_GROUP_TYPE, CHAT_ROOM_1ON1_TYPE].includes(room.type)
    );
    store.chatRoomsUnreadMessages = _.sum(
      _.map(store.chatRooms, 'unreadMessages')
    );
  }

  async function updateUserAgentsConnected() {
    let userAgentIds = [];
    _.forEach(store.originalRooms, (room) => {
      _.forEach(room.userAgents, (userAgent) => {
        userAgentIds.push(userAgent.userAgent.id);
      });
    });
    userAgentIds = _.uniq(userAgentIds);
    const { userAgentsConnected } = await RoomService.getConnectedStatus({
      userAgentIds,
    });
    _.forEach(store.originalRooms, (room) => {
      _.forEach(room.userAgents, (userAgent) => {
        userAgent.connected =
          userAgentsConnected.indexOf(userAgent.userAgent.id) > -1;
      });
    });
    processChatRooms();
    render();
  }

  function handleOnClose() {
    store.opened = false;
    onClose();
  }

  function closeAll() {
    store.createType = null;
    store.selectedRoom = null;
    onRoomOpened(null);
    handleOnClose();

    // Esperamos a borrarlo para que se anime el que se cierra
    setTimeout(() => {
      store.subjectRooms = [];
      render();
    }, 300);
  }

  function closeSelectedRoom() {
    store.selectedRoom = null;
    store.selectedMessage = null;
    store.opened = true;
    // onRoomOpened(null);
    render();
  }

  async function searchRoomsByNameFilter() {
    try {
      const searchResults = await RoomService.search(
        store.nameFilter,
        store.selectedProgramID
      );
      store.searchResultsRooms = {
        _rooms: [],
        _userAgents: searchResults.userAgents,
        _messages: [],
      };
      const roomsById = _.keyBy(store.originalRooms, 'id');
      const roomsByKey = _.keyBy(store.originalRooms, 'key');
      _.forEach(searchResults.rooms, (roomId) => {
        store.searchResultsRooms._rooms.push(roomsById[roomId]);
      });
      _.forEach(searchResults.messages, (message) => {
        store.searchResultsRooms._messages.push({
          ...message,
          room: roomsByKey[message.room],
          userAgent: _.find(
            roomsByKey[message.room].userAgents,
            (u) => u.userAgent.id === message.userAgent
          ).userAgent,
        });
      });
      store.roomTypes = _.uniq(_.map(store.searchResultsRooms._rooms, 'type'));
      if (store.searchResultsRooms._userAgents.length) {
        store.roomTypes.push('userAgents');
      }
      if (store.searchResultsRooms._messages.length) {
        store.roomTypes.push('messages');
      }
      filterSearch();
    } catch (e) {
      addErrorAlert(getErrorMessage(e));
    }
    store.searching = false;
    render();
  }

  function hideCreate() {
    store.createType = null;
    render();
  }

  function closeCreate() {
    closeAll();
  }

  async function goToMessage(message) {
    store.selectedMessage = message;
    store.selectedMessage.query = store.nameFilter;
    store.selectedRoom = message.room;
    onRoomOpened(message.room);
    render();
  }

  async function getUserAgentChatRoom(roomKey) {
    const [, userAgent] = roomKey.split('USERAGENT:');

    let programId = store.selectedProgramID;

    if (!programId) {
      programId = _.map(store.originalRooms, (room) => room.program).find(
        (program) => program
      );
    }

    try {
      const { room } = await RoomService.createRoom({
        type: CHAT_ROOM_1ON1_TYPE,
        userAgents: [userAgent, userAgentId],
        program: programId,
      });
      return room;
    } catch (e) {
      return store.originalRooms.find(
        (r) =>
          r.type === CHAT_ROOM_1ON1_TYPE &&
          r.userAgents.length === 2 &&
          r.userAgents.some((ua) => ua.userAgent.id === userAgent) &&
          r.userAgents.some((ua) => ua.userAgent.id === userAgentId)
      );
    }
  }

  // ·····································
  // HANDLERS

  async function onClickRoom(val) {
    store.selectedMessage = null;
    let room = val;

    if (isString(room)) {
      if (room.includes('USERAGENT:')) {
        room = await getUserAgentChatRoom(room);
      } else {
        room = _.find(store.originalRooms, { key: room });
      }
    }

    if (room?.key === store.selectedRoom?.key) {
      return;
    }

    if (room.type) {
      const childrens = getRoomChildrens(store.originalRooms, room);

      if (room.type === GROUPED_ROOMS_SUBJECT_TYPE) {
        store.subjectRooms.push({
          ...room,
          childrens,
          unreadMessages: getTotalUnreadMessages(
            childrens,
            store.originalRooms
          ),
        });
        store.subjectRooms = _.cloneDeep(store.subjectRooms);
        render();
      } else {
        room.subjectRooms = room.parentRoom?.map((r) =>
          store.subjectRooms.find((p) => p.key === r)
        );
        store.selectedRoom = room;
        onRoomOpened(room);
        render();
      }
    } else {
      store.selectedRoom = room;
      onRoomOpened(room);
      render();
    }
  }

  function onClickTab(tab) {
    store.activeTab = tab;
    render();
  }

  function onClickMessage(message) {
    goToMessage(message);
  }

  function onCloseRoom() {
    closeAll();
  }

  function onAfterNewRoom(room) {
    store.createType = null;
    room.subjectRooms = room.parentRoom.map((r) =>
      store.subjectRooms.find((p) => p.key === r)
    );
    store.selectedRoom = room;
    onRoomOpened(room);
    render();
  }

  async function onFocusSwitch() {
    await RoomService.saveConfig({ muted: !store.config.muted });
    store.config.muted = !store.config.muted;
    render();
  }

  function onChangeNameFilter(e) {
    store.searching = true;
    store.nameFilter = e;
    render();
    debouncedSearch(searchRoomsByNameFilter);
  }

  function onChangeTypeFilter(e) {
    store.typeFilters = e;
    filterSearch();
    render();
  }

  function onCleanTypesFilter() {
    store.typeFilters = [];
    filterSearch();
    render();
  }

  function onBeforeNewGroup() {
    store.openNextAddedRoomType = CHAT_ROOM_GROUP_TYPE;
  }

  function onCreateChat() {
    store.createType = CHAT_ROOM_1ON1_TYPE;
    render();
  }

  function onNewGroup() {
    store.createType = CHAT_ROOM_GROUP_TYPE;
    render();
  }

  function onSelectProgram(program) {
    store.selectedProgramID = program.id;
    if (store.nameFilter) searchRoomsByNameFilter();
    processChatRooms();
    render();
  }

  async function onNewChat(e) {
    store.openNextAddedRoomType = CHAT_ROOM_1ON1_TYPE;
    const { room } = await RoomService.createRoom({
      type: CHAT_ROOM_1ON1_TYPE,
      userAgents: [e.id, userAgentId],
      program: store.selectedProgramID,
    });
    hideCreate();
    onAfterNewRoom(room);
  }

  async function onClickUserAgent(userAgent) {
    store.openNextAddedRoomType = isTeacher
      ? CHAT_ROOM_1ON1_PARENT_TYPE
      : CHAT_ROOM_1ON1_TYPE;
    const { room } = await RoomService.createRoom({
      type: CHAT_ROOM_1ON1_TYPE,
      userAgents: [userAgent.id, userAgentId],
      program: store.selectedProgramID,
    });
    store.nameFilter = '';
    onAfterNewRoom(room);
    onChangeNameFilter();
  }

  // ·····································
  // INITIAL DATA LOADING

  async function load() {
    const [centerConfig, originalRooms, config] = await Promise.all([
      RoomService.getCenterConfig(center?.id),
      RoomService.getRoomsList(),
      RoomService.getConfig(),
    ]);
    const programIds = _.uniq(_.map(originalRooms, 'program'));
    const programConfigs = await Promise.all(
      _.map(programIds, (programId) => RoomService.getProgramConfig(programId))
    );
    store.programConfig = {};
    _.forEach(programIds, (programId, index) => {
      store.programConfig[programId] = programConfigs[index];
    });
    store.centerConfig = centerConfig;
    store.originalRooms = originalRooms;
    store.config = config;

    await updateUserAgentsConnected();
    if (store.openAfterLoad) {
      const r = _.find(store.originalRooms, { key: store.openAfterLoad });
      onClickRoom(r);
      store.openAfterLoad = null;
    }
    render();
  }

  React.useEffect(() => {
    if (isTeacher !== null) load();
  }, [isTeacher]);

  // ·····································
  // EFFECTS

  const disabledProfilesForNewChat = React.useMemo(() => {
    const profiles = [];
    if (!store.centerConfig?.enableStudentsChats && isStudent) {
      profiles.push(USER_TYPE_STUDENT);
    }
    if (
      store.centerConfig?.disableChatsBetweenStudentsAndTeachers &&
      (isStudent || isTeacher)
    ) {
      profiles.push(isStudent ? USER_TYPE_TEACHER : USER_TYPE_STUDENT);
    }
    return profiles;
  }, [store.centerConfig, isStudent, isTeacher]);

  const disabledProfilesForNewGroup = React.useMemo(() => {
    const profiles = [...disabledProfilesForNewChat];
    if (isStudent && !store.centerConfig?.studentsCanAddTeachersToGroups) {
      profiles.push(USER_TYPE_TEACHER);
    }
    return profiles;
  }, [disabledProfilesForNewChat]);

  const openChatDrawer = React.useMemo(
    () => !!store.selectedRoom,
    [store.selectedRoom]
  );

  React.useEffect(() => {
    if (isString(openRoom) && openRoom.includes('~~')) {
      const [room] = openRoom.split('~~');
      onClickRoom(room);
    } else if (openRoom?.key) {
      onClickRoom(openRoom);
    }
  }, [openRoom]);

  React.useEffect(() => {
    store.opened = openedProp;
    render();
  }, [openedProp]);

  React.useEffect(() => {
    updateUserAgentsConnected();
    const interval = setInterval(() => {
      updateUserAgentsConnected();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // ·····································
  // SOCKETS

  SocketIoService.useOn(EVENT_CONFIG_CENTER, (event, eventData) => {
    if (eventData.center === center?.id) {
      store.centerConfig = eventData.config;
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_CONFIG_PROGRAM, (event, eventData) => {
    if (store.programConfig?.[eventData.program]) {
      store.programConfig[eventData.program] = eventData.config;
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_ADDED, (event, eventData) => {
    if (
      store.openNextAddedRoomType &&
      eventData.room.includes(
        `leemons.comunica.room.${store.openNextAddedRoomType}`
      )
    ) {
      store.openAfterLoad = eventData.room;
    }
    store.openNextAddedRoomType = null;
    debouncedFunction(load);
  });

  SocketIoService.useOn(EVENT_ROOM_USER_ADDED, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.key });
    if (index >= 0) {
      const i = _.findIndex(
        store.originalRooms[index].userAgents,
        (item) => item.userAgent.id === eventData.userAgent.userAgent.id
      );
      if (i >= 1) {
        store.originalRooms[index].userAgents[i] = eventData.userAgent;
      } else {
        store.originalRooms[index].userAgents.push(eventData.userAgent);
      }
      debouncedFunction2(() => {
        processChatRooms();
        render();
      });
    }
  });

  SocketIoService.useOn(EVENT_CONFIG_ROOM, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.room });
    if (index >= 0) {
      store.originalRooms[index].muted = !!eventData.muted;
      store.originalRooms[index].attached = eventData.attached;
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_REMOVE, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.key });
    if (index >= 0) {
      store.originalRooms.splice(index, 1);
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_USERS_REMOVED, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.room });
    if (index >= 0) {
      store.originalRooms[index].userAgents = _.map(
        store.originalRooms[index].userAgents,
        (item) => {
          let { deleted } = item;
          if (eventData.userAgents.includes(item.userAgent.id)) deleted = true;
          return {
            ...item,
            deleted,
          };
        }
      );
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_UPDATE_NAME, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.key });
    if (index >= 0) {
      store.originalRooms[index].name = eventData.name;
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_ROOM_UPDATE_IMAGE, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.key });
    if (index >= 0) {
      store.originalRooms[index].image = eventData.image;
      if (!store.originalRooms[index].imageSeed)
        store.originalRooms[index].imageSeed = 0;
      store.originalRooms[index].imageSeed++;
      processChatRooms();
      render();
    }
  });

  SocketIoService.useOnAny((event, eventData) => {
    if (
      [
        EVENT_CONFIG_CENTER,
        EVENT_CONFIG_PROGRAM,
        EVENT_CONFIG_ROOM,
        EVENT_ROOM_ADDED,
        EVENT_ROOM_USER_ADDED,
        EVENT_ROOM_USERS_REMOVED,
        EVENT_ROOM_REMOVE,
        EVENT_ROOM_UPDATE_NAME,
        EVENT_ROOM_UPDATE_IMAGE,
      ].includes(event)
    ) {
      return;
    }

    _.forEach(store.originalRooms, (room) => {
      if (`COMUNICA:ROOM:${room.key}` === event) {
        const index = _.findIndex(store.originalRooms, { id: room.id });
        store.originalRooms[index].unreadMessages += 1;
        store.originalRooms[index].updatedAt = eventData.updatedAt;
        store.originalRooms[index].lastMessage = eventData;
        store.originalRooms.unshift(store.originalRooms.splice(index, 1)[0]);
        processChatRooms();
        render();
        return false;
      }
      if (`COMUNICA:ROOM:READED:${room.key}` === event) {
        const index = _.findIndex(store.originalRooms, { id: room.id });
        store.originalRooms[index].unreadMessages = 0;
        processChatRooms();
        render();
        setTimeout(() => {
          processChatRooms();
          render();
        }, 20);
        return false;
      }
      return true;
    });
  });

  // ·····································
  // RENDER

  let canAddGroup = true;
  if (isStudent) {
    canAddGroup = false;
    if (store.centerConfig?.enableStudentsCreateGroups) {
      canAddGroup = true;
    }
  }

  return (
    <>
      <BaseDrawer
        opened={store.opened && !store.selectedRoom}
        size={400}
        close={false}
        empty
      >
        <Box className={classes.wrapper}>
          <ChatListHeader
            t={t}
            isStudent={isStudent}
            isTeacher={isTeacher}
            canAddGroup={canAddGroup}
            roomTypes={store.roomTypes}
            programs={store.programs}
            filter={store.nameFilter}
            typeFilters={store.typeFilters}
            programsConfig={store.programConfig}
            onCleanTypesFilter={onCleanTypesFilter}
            onChangeFilter={onChangeNameFilter}
            onChangeTypeFilter={onChangeTypeFilter}
            onSelectProgram={onSelectProgram}
            onFocusSwitch={onFocusSwitch}
            onNewChat={onCreateChat}
            onNewGroup={onNewGroup}
            onClose={handleOnClose}
          />
          {!store.nameFilter ? (
            <ChatListTabs
              t={t}
              activeTab={store.activeTab}
              roomChats={store.chatRooms}
              roomFavs={store.favRooms}
              roomSubjects={store.subjectRooms}
              roomActivities={store.assignableRooms}
              canAddGroup={canAddGroup}
              onTabClick={onClickTab}
              onRoomClick={onClickRoom}
              onNewChat={onCreateChat}
              onNewGroup={onNewGroup}
            />
          ) : (
            <ChatListResults
              t={t}
              searching={store.searching}
              searchResults={store.searchResultsRooms}
              onClickUserAgent={onClickUserAgent}
              onClickMessage={onClickMessage}
              onClickRoom={onClickRoom}
              highlight={store.nameFilter}
            />
          )}
        </Box>
      </BaseDrawer>

      <ChatDrawer
        onClose={onCloseRoom}
        onReturn={closeSelectedRoom}
        selectedMessage={store.selectedMessage}
        room={store.selectedRoom?.key}
        opened={openChatDrawer}
      />

      <ChatInfoDrawer
        opened={store.createType === CHAT_ROOM_GROUP_TYPE && canAddGroup}
        program={store.selectedProgramID}
        disabledProfiles={disabledProfilesForNewGroup}
        onBeforeNewGroup={onBeforeNewGroup}
        onAfterNewGroup={onAfterNewRoom}
        onReturn={hideCreate}
        onClose={closeCreate}
      />

      <ChatAddUsersDrawer
        newChatMode
        disabledProfiles={disabledProfilesForNewChat}
        program={store.selectedProgramID}
        room={store.roomNewChat}
        opened={store.createType === CHAT_ROOM_1ON1_TYPE}
        onSave={onNewChat}
        onReturn={hideCreate}
        onClose={closeCreate}
      />
    </>
  );
}

ChatListDrawer.propTypes = {
  opened: PropTypes.bool,
  openRoom: PropTypes.string,
  onRoomOpened: PropTypes.func,
  onClose: PropTypes.func,
};

export { ChatListDrawer };
