import React from 'react';
import _, { noop } from 'lodash';
import {
  Box,
  Text,
  Stack,
  Highlight,
  TextClamp,
  stringToHslColor,
} from '@bubbles-ui/components';
import {
  FavoriteHeartIcon,
  VolumeControlOffIcon,
} from '@bubbles-ui/icons/solid';
import PropTypes from 'prop-types';
import { RoomAvatar } from '@comunica/components/RoomAvatar/RoomAvatar';
import getRoomParsed from '@comunica/helpers/getRoomParsed';
import { useTransformDate } from '@comunica/hooks/useTransformDate';
import {
  CHAT_ROOM_1ON1_PARENT_TYPE,
  CHAT_ROOM_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_ALL_TYPE,
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  CHAT_ROOM_SUBJECT_TEACHERS_TYPE,
  GROUPED_ROOMS_SUBJECT_TYPE,
  MESSAGE_TYPE_TEXT,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
} from '@comunica/constants';
import isTeacherByRoom from '@comunica/helpers/isTeacherByRoom';
import { ChatListDrawerItemStyles } from './ChatListDrawerItem.styles';

function ChatListDrawerItem({
  t,
  room: _room,
  parent: parentRoom,
  highlight,
  hideFav = false,
  avatarStyle = 'solid',
  onClick = noop,
}) {
  const { classes } = ChatListDrawerItemStyles(
    { room: _room },
    { name: 'ChatListDrawerItem' }
  );

  const room = React.useMemo(() => getRoomParsed(_room), [_room]);

  const date = useTransformDate(_room.updatedAt);

  const isTeacher = isTeacherByRoom(_room);
  const isRoomStudentTeacherSubject =
    isTeacher && _room.type === CHAT_ROOM_SUBJECT_TEACHERS_TYPE;
  const isRoomStudentTeacherActivity =
    isTeacher && _room.type === CHAT_ROOM_ACTIVITY_1ON1_TYPE;

  let showSubName = true;

  if (parentRoom?.type === GROUPED_ROOMS_SUBJECT_TYPE && !isTeacher) {
    showSubName = false;
  }

  function renderLastMessageContent() {
    if (room.lastMessage) {
      if (room.lastMessage.message.type === MESSAGE_TYPE_TEXT) {
        return (
          <Box className={classes.subName}>
            <Box className={classes.lastMessage}>
              {room.type !== CHAT_ROOM_1ON1_TYPE &&
              room.type !== CHAT_ROOM_1ON1_PARENT_TYPE &&
              room.lastMessage._userAgent ? (
                <b>{room.lastMessage._userAgent.user.name}:&nbsp;</b>
              ) : null}
              {room.lastMessage.message.content}
            </Box>
          </Box>
        );
      }
    } else if (room.type === CHAT_ROOM_SUBJECT_ALL_TYPE) {
      return <Box className={classes.lastMessage}>{t('empty.messages')}</Box>;
    }
    return null;
  }

  const getStudentName = () => {
    const student = room.userAgents.filter(
      (ua) => ua.userAgent.profile.sysName === USER_TYPE_STUDENT
    );

    return `${student[0].userAgent?.user?.name} ${student[0].userAgent?.user?.surnames}`;
  };

  const isSubjectRoom =
    isTeacher && room.type === CHAT_ROOM_SUBJECT_TEACHERS_TYPE;

  const isActivityRoom =
    isTeacher && room.type === CHAT_ROOM_ACTIVITY_1ON1_TYPE;

  const otherUserStudent = _.filter(
    room.userAgents,
    (e) => e.userAgent.profile.sysName !== USER_TYPE_TEACHER
  );
  const otherUserTeacher = _.filter(
    room.userAgents,
    (e) => e.userAgent.profile.sysName === USER_TYPE_TEACHER
  );

  const isAssignableRoom = room.type.includes('assignable');

  if (isSubjectRoom || isActivityRoom) {
    room.nameReplaces = `${otherUserStudent[0].userAgent.user.name} ${otherUserStudent[0].userAgent.user.surnames}`;
    room.name = `${otherUserStudent[0].userAgent.user.name} ${otherUserStudent[0].userAgent.user.surnames}`;
    room.image = otherUserStudent[0].userAgent.user.avatar ?? null;
    room.icon = null;
    room.bgColor = room.image ? null : stringToHslColor(room.name, 50, 50);
  }

  if (room.type === CHAT_ROOM_1ON1_TYPE) {
    room.bgColor = stringToHslColor(room.name, 50, 50);
    room.subName = t('1on1Teacher');
  }

  if (room.type === CHAT_ROOM_ACTIVITY_ALL_TYPE) {
    room.subName = room.metadata.headerName;
  }

  return (
    <Box onClick={onClick} className={classes.item}>
      <RoomAvatar
        room={room}
        size={44}
        style={avatarStyle}
        nameReplaces={
          isRoomStudentTeacherSubject
            ? getStudentName()
            : t(room.name, room.nameReplaces, false, room.name)
        }
      />
      <Box className={classes.itemContent}>
        <Box className={classes.date}>{date}</Box>
        <Box className={classes.itemTitleContainer}>
          {!isRoomStudentTeacherSubject &&
            !isRoomStudentTeacherActivity &&
            !!room.name && (
              <Stack
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  lineHeight: '1.2rem',
                }}
              >
                <Box className={classes.name}>
                  <TextClamp lines={1}>
                    <Text>
                      <Highlight highlight={highlight}>
                        {t(room.name, room.nameReplaces, false, room.name)}
                      </Highlight>
                    </Text>
                  </TextClamp>
                </Box>
              </Stack>
            )}
          {isRoomStudentTeacherActivity && (
            <Stack
              alignItems="center"
              justifyContent="space-between"
              sx={{
                lineHeight: '1.2rem',
              }}
            >
              <Box className={classes.name}>
                <TextClamp lines={1}>
                  <Text>
                    <Highlight highlight={highlight}>
                      {getStudentName()}
                    </Highlight>
                  </Text>
                </TextClamp>
              </Box>
            </Stack>
          )}
          {room.type !== CHAT_ROOM_1ON1_TYPE &&
            room.type !== CHAT_ROOM_1ON1_PARENT_TYPE &&
            !!room.subName &&
            showSubName && (
              <Box sx={{ lineHeight: '0.7rem' }}>
                <Box className={classes.subName}>
                  <TextClamp lines={1}>
                    <Text>
                      <Highlight highlight={highlight}>
                        {t(room.subName, {}, false, room.subName)}
                      </Highlight>
                    </Text>
                  </TextClamp>
                </Box>
              </Box>
            )}
          {isRoomStudentTeacherSubject && (
            <Stack
              alignItems="center"
              justifyContent="space-between"
              sx={{
                lineHeight: '1.2rem',
              }}
            >
              <Box className={classes.name}>
                <Highlight highlight={highlight}>{getStudentName()}</Highlight>
              </Box>
            </Stack>
          )}
          {renderLastMessageContent()}
        </Box>
        {room.muted || room.unreadMessages || room.attached ? (
          <Box className={classes.itemIcons}>
            {!!room.attached && !hideFav && (
              <Box sx={{ display: 'flex', color: '#FF5470' }}>
                <FavoriteHeartIcon />
              </Box>
            )}
            {!!room.muted && <VolumeControlOffIcon />}
            {!!room.unreadMessages && (
              <Box className={classes.unreadMessages}>
                {room.unreadMessages}
              </Box>
            )}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

ChatListDrawerItem.propTypes = {
  t: PropTypes.func,
  room: PropTypes.any,
  onClick: PropTypes.func,
  parent: PropTypes.any,
  highlight: PropTypes.string,
  hideFav: PropTypes.bool,
  avatarStyle: PropTypes.string,
};

export { ChatListDrawerItem };
export default ChatListDrawerItem;
