import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import hooks from 'leemons-hooks';
import { Box, useDebouncedCallback } from '@bubbles-ui/components';
import { CommentIcon, VolumeControlOffIcon } from '@bubbles-ui/icons/solid';
import { useNotifications } from '@bubbles-ui/notifications';
import { useStore } from '@common';
import { ChatListDrawer, RoomAvatar } from '@comunica/components';
import getRoomParsed from '@comunica/helpers/getRoomParsed';
import getRoomsByParent from '@comunica/helpers/getRoomsByParent';
import isStudentTeacherChatRoom from '@comunica/helpers/isStudentTeacherChatRoom';
import isStudentsChatRoom from '@comunica/helpers/isStudentsChatRoom';
import prefixPN from '@comunica/helpers/prefixPN';
import SocketIoService from '@mqtt-socket-io/service';
import useTranslateLoader from '@multilanguage/useTranslateLoader';
import { getSessionCenter, getSessionUserAgent } from '@users/session';
import {
  CHAT_ROOM_SUBJECT_ALL_TYPE,
  EVENT_CONFIG,
  EVENT_CONFIG_CENTER,
  EVENT_CONFIG_PROGRAM,
  EVENT_CONFIG_ROOM,
  EVENT_ROOM_ADDED,
  EVENT_ROOM_USER_ADDED,
  MESSAGE_TYPE_TEXT,
} from '@comunica/constants';
import { RoomService } from '../../RoomService';
import { ChatHubStyles } from './ChatHub.styles';

function ChatHub({ onShowDrawerChange, openRoom, showButton }) {
  const debouncedFunction = useDebouncedCallback(100);
  const debouncedFunction2 = useDebouncedCallback(300);
  const [store, render] = useStore({ openRoom });
  const [t] = useTranslateLoader(prefixPN('chatListDrawer'));
  const notifications = useNotifications('chat');
  const { classes } = ChatHubStyles({}, { name: 'ChatHub' });
  const center = getSessionCenter();
  const userAgentId = getSessionUserAgent();

  // ····················································
  // METHODS

  function calculeRoomsData() {
    store.unreadMessages = 0;
    _.forEach(store.originalRooms, (room) => {
      store.unreadMessages += room.unreadMessages;
    });
  }

  function processRooms() {
    store.parentRooms = _.orderBy(
      getRoomsByParent(store.originalRooms),
      ['attached'],
      ['asc']
    );
    if (!store.centerConfig?.enableStudentsChats) {
      store.parentRooms = _.filter(
        store.parentRooms,
        (room) => !isStudentsChatRoom(room)
      );
    }
    if (store.centerConfig?.disableChatsBetweenStudentsAndTeachers) {
      store.parentRooms = _.filter(
        store.parentRooms,
        (room) => !isStudentTeacherChatRoom(room)
      );
    }
    store.parentRooms = _.filter(
      store.parentRooms,
      (room) =>
        !(
          room.type === CHAT_ROOM_SUBJECT_ALL_TYPE &&
          !store.programConfig[room.program]?.enableSubjectsRoom
        )
    );
    calculeRoomsData();
  }

  // ····················································
  // INITIAL DATA LOADING

  async function load() {
    store.userAgent = userAgentId;
    const [centerConfig, originalRooms, config] = await Promise.all([
      RoomService.getCenterConfig(center?.id),
      RoomService.getRoomsList(),
      RoomService.getConfig(),
    ]);
    const programIds = _.uniq(_.map(originalRooms, 'program'));
    const programConfigs = await Promise.all(
      _.map(programIds, (programId) => RoomService.getProgramConfig(programId))
    );
    store.programConfig = {};
    _.forEach(programIds, (programId, index) => {
      store.programConfig[programId] = programConfigs[index];
    });
    store.config = config;
    store.centerConfig = centerConfig;
    store.originalRooms = originalRooms;
    processRooms();
    render();
  }

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (openRoom) {
      store.openRoom = openRoom;
      render();
    }
  }, [openRoom]);

  // ····················································
  // HANDLERS

  function closeDrawer() {
    store.showDrawer = false;
    onShowDrawerChange(false);
    render();
  }

  function toggleDrawer() {
    store.showDrawer = !store.showDrawer;
    onShowDrawerChange(store.showDrawer);
    render();
  }

  function onRoomOpened(room) {
    store.roomOpened = room;
    store.openRoom = null;
  }

  function _onRoomOpened({ args: [room] }) {
    onRoomOpened(room);
  }

  function _closeDrawer() {
    closeDrawer();
  }

  // ····················································
  // SOCKET & HOOKS EVENTS

  React.useEffect(() => {
    hooks.addAction('chat:onRoomOpened', _onRoomOpened);
    hooks.addAction('chat:closeDrawer', _closeDrawer);
    return () => {
      hooks.removeAction('chat:onRoomOpened', _onRoomOpened);
      hooks.removeAction('chat:closeDrawer', _closeDrawer);
    };
  }, []);

  SocketIoService.useOn(EVENT_CONFIG_CENTER, (event, eventData) => {
    if (eventData.center === center?.id) {
      store.centerConfig = eventData.config;
      processRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_CONFIG_PROGRAM, (event, eventData) => {
    if (store.programConfig[eventData.program]) {
      store.programConfig[eventData.program] = eventData.config;
      processRooms();
      render();
    }
  });

  SocketIoService.useOn(EVENT_CONFIG, (event, eventData) => {
    store.config = eventData;
    processRooms();
    render();
  });

  SocketIoService.useOn(EVENT_CONFIG_ROOM, (event, eventData) => {
    const index = _.findIndex(store.originalRooms, { key: eventData.room });
    store.originalRooms[index].muted = !!eventData.muted;
    render();
  });

  SocketIoService.useOn(EVENT_ROOM_ADDED, () => {
    debouncedFunction(load);
  });

  SocketIoService.useOn(EVENT_ROOM_USER_ADDED, () => {
    debouncedFunction2(load);
  });

  SocketIoService.useOnAny((event, eventData) => {
    if (
      [
        EVENT_CONFIG_CENTER,
        EVENT_CONFIG_PROGRAM,
        EVENT_CONFIG,
        EVENT_CONFIG_ROOM,
        EVENT_ROOM_ADDED,
        EVENT_ROOM_USER_ADDED,
      ].includes(event)
    ) {
      return;
    }

    let needUpdate = false;

    _.forEach(store.originalRooms, (room, index) => {
      if (`COMUNICA:ROOM:${room.key}` === event) {
        if (
          store.userAgent !== eventData.userAgent &&
          !room.muted &&
          !store.config?.muted &&
          store.roomOpened?.id !== room.id &&
          eventData.message?.type === MESSAGE_TYPE_TEXT
        ) {
          notifications.showNotification({
            onClick: (e, notification) => {
              let node = e.target;
              if (e.target.nodeName === 'svg') {
                node = e.target.parentNode;
              }
              if (
                !node.className.includes(
                  'mantine-Notification_chat-closeButton'
                )
              ) {
                store.openRoom = room;
                notifications.hideNotification(notification.id);
                render();
              }
            },
            title: t(room.name, room.nameReplaces, false, room.name),
            message: eventData.message.content,
            leftSide: <RoomAvatar room={getRoomParsed(room)} size={32} />,
          });
        }
        store.originalRooms[index].unreadMessages += 1;
        needUpdate = true;
        return false;
      }
      if (`COMUNICA:ROOM:READED:${room.key}` === event) {
        store.originalRooms[index].unreadMessages = 0;
        needUpdate = true;
        return false;
      }
    });

    if (needUpdate) {
      processRooms();
      render();
    }
  });

  // ····················································
  // RENDER

  return (
    <>
      {showButton && (
        <Box className={classes.root}>
          <Box className={classes.chatBullet} onClick={toggleDrawer}>
            {!store.config?.muted && (
              <CommentIcon className={classes.chatIcon} />
            )}
            {store.unreadMessages > 0 && !store.config?.muted && (
              <Box className={classes.unreadMessages}>
                {store.unreadMessages > 99 ? '+99' : store.unreadMessages}
              </Box>
            )}
            {store.config?.muted && (
              <VolumeControlOffIcon className={classes.chatIcon} />
            )}
          </Box>
        </Box>
      )}
      <ChatListDrawer
        opened={store.showDrawer}
        openRoom={store.openRoom}
        onRoomOpened={onRoomOpened}
        onClose={closeDrawer}
      />
    </>
  );
}

ChatHub.propTypes = {
  onShowDrawerChange: PropTypes.func,
  openRoom: PropTypes.string,
  showButton: PropTypes.bool,
};

export { ChatHub };
