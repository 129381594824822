import { useVariantForQueryKey } from '@common/queries';
import { useQuery } from '@tanstack/react-query';
import getUserPool from '@users-cognito/requests/getUserPool';
import { getUserPoolKey } from '../keys/userPool';

/**
 * @typedef {import('@tanstack/react-query').UseQueryOptions} UseQueryOptions
 * @typedef {import('@users-cognito/requests/getUserPool').UserPool} UserPool
 * @typedef {import('@tanstack/react-query').UseQueryResult<UserPool>} UseUserPoolResult
 *
 *
 * @param {UseQueryOptions} options
 * @returns {UseUserPoolResult}
 */
function useUserPool(options) {
  const queryKey = getUserPoolKey;
  const queryFn = getUserPool;

  useVariantForQueryKey(queryKey, { modificationTrend: 'standard' });

  return useQuery({
    ...options,
    queryKey,
    queryFn,
  });
}

export default useUserPool;
