import { createStyles } from '@bubbles-ui/components';

const ChatDrawerStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 9999,
    display: 'flex',
    height: '100%',
    justifyContent: 'start',
    borderLeft: `1px solid ${theme.colors.ui01}`,
    width: 400,
    flexDirection: 'column',
  },
  drawer: {
    overflow: 'hidden',
    '.mantine-Popover-dropdown': {
      left: 'auto!important',
      right: '0px!important',
      border: 0,
      backgroundColor: 'transparent',
    },
    '.mantine-Drawer-content': {
      overflow: 'hidden',
    },
  },
  messages: {
    flex: 1,
    paddingInline: theme.spacing[4],
    paddingBottom: theme.spacing[4],
    overflowY: 'auto',
    backgroundColor: '#F8F9FB',
  },
}));

export { ChatDrawerStyles };
