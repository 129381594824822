import {
  CHAT_ROOM_1ON1_PARENT_TYPE,
  CHAT_ROOM_1ON1_TYPE,
  CHAT_ROOM_ACTIVITY_1ON1_TYPE,
  CHAT_ROOM_GROUP_TYPE,
  USER_TYPE_STUDENT,
} from '@comunica/constants';
import getChatUserAgent from '@comunica/helpers/getChatUserAgent';
import isTeacherByRoom from '@comunica/helpers/isTeacherByRoom';
import { getCentersWithToken } from '@users/session';
import _ from 'lodash';

export function getName(userAgent) {
  return `${userAgent.user.name}${
    userAgent.user.surnames ? ` ${userAgent.user.surnames}` : ''
  }`;
}

export function getRoomParsed(room) {
  const isTeacher = isTeacherByRoom(room);

  const config = {
    ...room,
  };
  if (isTeacher && room.type === CHAT_ROOM_ACTIVITY_1ON1_TYPE) {
    const student = _.find(
      _.map(room.userAgents, 'userAgent'),
      (userAgent) => userAgent?.profile?.sysName === USER_TYPE_STUDENT
    );
    // config.name = getName(student);
    config.name = config.metadata.headerName;
    config.metadata.headerSubName = getName(student);
    // config.image = student.user.avatar;
    // config.imageIsUrl = true;
    // config.imageIsUser = true;
    // config.metadata.headerImageIsUrl = false;
    // config.metadata.headerImageIsUser = false;
  }
  if (!isTeacher && room.type === CHAT_ROOM_ACTIVITY_1ON1_TYPE) {
    config.metadata.headerSubName = config.name;
    config.metadata.headerIcon = config.icon;
    config.metadata.headerSubNameReplaces = config.nameReplaces;
  }
  if (
    room.type === CHAT_ROOM_1ON1_TYPE ||
    room.type === CHAT_ROOM_1ON1_PARENT_TYPE
  ) {
    const userAgentData = getChatUserAgent(room.userAgents);
    config.name = userAgentData ? getName(userAgentData?.userAgent) : '';
    config.image = userAgentData?.userAgent.user.avatar;
    config.imageIsUrl = true;
    config.imageIsUser = true;
    config.subName = userAgentData?.userAgent?.profile?.name;
  }
  if (room.type === CHAT_ROOM_GROUP_TYPE) {
    const agentId = getCentersWithToken()[0].userAgentId;
    const userAgents = _.filter(
      room.userAgents,
      (item) => item.userAgent.id !== agentId && !item.deleted
    );
    config.subName = '';
    _.forEach(userAgents, (item, index) => {
      config.subName += `${index > 0 ? ', ' : ''}${getName(item.userAgent)}`;
    });
  }
  if (_.isObject(room.lastMessage)) {
    config.lastMessage._userAgent = _.find(
      room.userAgents,
      (item) => item.userAgent.id === room.lastMessage.userAgent
    )?.userAgent;
  }
  return config;
}

export default getRoomParsed;
