import { createStyles } from '@bubbles-ui/components';

// eslint-disable-next-line import/prefer-default-export
export const ChatListDrawerStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 9999,
    display: 'block',
    height: '100%',
    justifyContent: 'start',
    borderLeft: `1px solid ${theme.colors.ui01}`,
    width: 400,
    flexDirection: 'column',
    backgroundColor: '#F8F9FB',
  },
  header: {
    paddingInline: theme.spacing[5],
    height: 72,
    backgroundColor: theme.colors.mainWhite,
    zIndex: 2,
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: theme.spacing[2],
  },
  title: {
    paddingLeft: theme.spacing[7],
    paddingRight: theme.spacing[7],
    paddingBottom: theme.spacing[6],
  },
  input: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing[2],
    paddingLeft: theme.spacing[5],
    paddingRight: theme.spacing[5],
    paddingBottom: theme.spacing[2],
    paddingTop: theme.spacing[1],
    backgroundColor: theme.colors.mainWhite,
  },
  searchTitle: {
    paddingLeft: theme.spacing[5],
    paddingRight: theme.spacing[5],
    fontFamily: 'Albert Sans',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: theme.other.global.content.color.text.emphasis,
  },
  listItems: {
    backgroundColor: '#F8F9FB',
  },
  filterContainer: {
    padding: theme.spacing[4],
  },
  filterClean: {
    padding: theme.spacing[4],
    borderTop: `1px solid ${theme.other.global.border.color.line.muted}`,
  },
  config: {
    padding: theme.spacing[2],
    maxWidth: '250px',
    '.mantine-Button-inner': {
      justifyContent: 'start',
    },
  },
  tab: {
    backgroundColor: theme.colors.mainWhite,
    color: theme.other.global.content.color.text.default,
  },
  emptyWrapper: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyImage: {
    marginTop: theme.spacing[6],
    marginBottom: theme.spacing[6],
  },
  emptyTitle: {
    fontSize: 23,
    fontWeight: 500,
    display: 'block',
    marginBottom: theme.spacing[4],
  },
  emptyText: {
    fontSize: 18,
    fontWeight: 400,
  },
}));
