import _ from 'lodash';
import { getCentersWithToken } from '@users/session';
import { USER_TYPE_TEACHER } from '@comunica/constants';

export function isTeacherByRoom(room) {
  const { userAgentId } = getCentersWithToken()[0];
  const _userAgent = _.find(_.map(room.userAgents, 'userAgent'), {
    id: userAgentId,
  });
  return _userAgent?.profile.sysName === USER_TYPE_TEACHER;
}

export default isTeacherByRoom;
