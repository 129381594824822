import { createStyles } from '@bubbles-ui/components';
import getChatUserAgent from '@comunica/helpers/getChatUserAgent'; // eslint-disable-next-line import/prefer-default-export

// eslint-disable-next-line import/prefer-default-export
export const RoomAvatarStyles = createStyles((theme, { size, room }) => {
  let userAgentData = null;
  if (room.type === 'chat' || room.type === 'chat-parent') {
    userAgentData = getChatUserAgent(room.userAgents);
  }
  return {
    itemImage: {
      display: 'inline-block',
    },
    itemContent: {
      position: 'relative',
      display: 'flex',
      '&:after': {
        display:
          userAgentData?.userAgent.profile.sysName === 'teacher'
            ? 'block'
            : 'none',
        content: '""',
        position: 'absolute',
        top: -1,
        left: -1,
        width: 'calc(100% + 2px)',
        height: 'calc(100% + 2px)',
        borderRadius: '50%',
        overflow: 'hidden',
      },
    },
    image: {
      borderRadius: '50%',
      overflow: 'hidden',
      img: {
        borderRadius: '50%',
      },
    },
    iconSmall: {
      width: size * 0.4286,
      height: size * 0.4286,
      overflow: 'hidden',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
      img: {
        filter: 'brightness(0) invert(1)',
      },
    },
    attachedIcon: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: size * 0.4286,
      height: size * 0.4286,
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
      img: {
        filter: 'brightness(0) invert(1)',
      },
    },
    connected: {
      position: 'absolute',
      border: `2px solid ${theme.colors.mainWhite}`,
      right: -5,
      top: 0,
      width: size * 0.3586,
      height: size * 0.3586,
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
    },
    itemIconContainer: {
      boxSizing: 'content-box',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: size,
      height: size,
      img: {
        filter: 'brightness(0) invert(1)',
      },
    },
    itemImageContainer: {
      boxSizing: 'content-box',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: size,
      height: size,
    },
  };
});
